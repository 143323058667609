import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import API from "../../common/api";
import SubmenuButtons from "../../components/SubmenuButtons";
import { leaveInfoButtonLabel } from "../../constants/buttonLabelName";
import { leaveInfoRoutes } from "../../constants/subMenuRoutes";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
}));

function LeaveInfoAdd() {
  const classes = useStyles();
  const { control, register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();
  const onSubmit = async (data) => {
    // console.log(JSON.stringify(data));

    const res = await API.post("/leaveInfo", data);
    if (res.success === 1) {
      history.push("/leaveInfo");
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const [unitNames, setUnitNames] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const year = new Date().getFullYear();
    const years = Array.from(new Array(20), (val, index) => index + year);
    setYearList(years);

    async function fetchData() {
      const resUnitNames = await API.get(`/settings/unitName`);
      console.log("RES: " + JSON.stringify(resUnitNames));
      if (resUnitNames.data) {
        setUnitNames(resUnitNames.data);
      }

      const resDesignations = await API.get(`/settings/designation`);
      console.log("RES: " + JSON.stringify(resDesignations));
      if (resDesignations.data) {
        setDesignations(resDesignations.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const [monthList, setMonthList] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const [yearList, setYearList] = useState([]);

  const [dayList, setDayList] = useState([
    0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
    9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5,
    17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24,
    24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31,
  ]);

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={leaveInfoRoutes}
        buttonLabelName={leaveInfoButtonLabel}
      />
      <h1>Add Leave Information</h1>
      <Paper className={classes.paper}>
        <List className={classes.root}>
          {/* autoComplete="off" */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container>
              <Grid item>
                {/* Unit Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="unit_name_leave_info"
                        className={classes.labelText}
                      >
                        Unit Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        className={classes.dropDown}
                        name="unit_name_leave_info"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Unit Name
                        </option>
                        {unitNames &&
                          unitNames.map((d, index) => {
                            return (
                              <option value={d.unit_name} key={index}>
                                {d.unit_name}
                              </option>
                            );
                          })}
                      </select>
                    </Grid>
                    {errors.unit_name_leave_info && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.unit_name_leave_info?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Employee ID */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="employee_id_leave_info"
                        className={classes.labelText}
                      >
                        Employee ID:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="employee_id_leave_info"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="employee_id_leave_info"
                      />
                    </Grid>
                    {errors.employee_id_leave_info && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.employee_id_leave_info?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Date Leave Info */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="date_leave_info"
                        className={classes.labelText}
                      >
                        Date:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="date_leave_info"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="date_leave_info"
                      />
                    </Grid>
                    {errors.date_leave_info && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.date_leave_info?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Month */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="month_leave_info"
                        className={classes.labelText}
                      >
                        Month:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        className={classes.dropDown}
                        name="month_leave_info"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Month
                        </option>
                        {monthList &&
                          monthList.map((d, index) => {
                            return (
                              <option value={d} key={index}>
                                {d}
                              </option>
                            );
                          })}
                      </select>
                    </Grid>
                    {errors.month_leave_info && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.month_leave_info?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Year */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="year_leave_info"
                        className={classes.labelText}
                      >
                        Year:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        className={classes.dropDown}
                        name="year_leave_info"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Year
                        </option>
                        {yearList &&
                          yearList.map((d, index) => {
                            return (
                              <option value={d} key={index}>
                                {d}
                              </option>
                            );
                          })}
                      </select>
                    </Grid>
                    {errors.year_leave_info && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.year_leave_info?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* Leave Count */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="leave_count_leave_info"
                        className={classes.labelText}
                      >
                        Leave Count:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        className={classes.dropDown}
                        name="leave_count_leave_info"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Days
                        </option>
                        {dayList &&
                          dayList.map((d, index) => {
                            return (
                              <option value={d} key={index}>
                                {d}
                              </option>
                            );
                          })}
                      </select>
                    </Grid>
                    {errors.leave_count_leave_info && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.leave_count_leave_info?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </Grid>

              {/* Save Button */}
              <ListItem className={classes.inputRow}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          </form>
        </List>
      </Paper>
    </main>
  );
}

export default withRouter(LeaveInfoAdd);
