import React from "react";
import { hasChildren } from "./utils";
import SingleLevel from "./SingleLevel";
import MultiLevel from "./MultiLevel";
function MenuItem({ item }) {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
}

export default MenuItem;
