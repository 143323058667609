import axios from "axios";
import { toast } from "react-toastify";
import { AXIOS_BASE_URL } from "../constants/constants";
const api = axios.create({
  baseURL: AXIOS_BASE_URL,
});
// api.defaults.headers.common["Authorization"] = "Auth From api";

api.interceptors.request.use((request) => {
  console.log("Request-> " + JSON.stringify(request));
  return request;
});

api.interceptors.response.use((response) => {
  console.log("Response-> " + JSON.stringify(response.data));
  if (response.data.success === 0) {
    console.log(typeof response.data.success + " - " + response.data.message);
    toast.error(response.data.message);
    return {};
  } else {
    toast.success(response.data.message);
    return response.data;
  }
});

export default api;
