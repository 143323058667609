import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import API from "../../common/api";
import SubmenuButtons from "../../components/SubmenuButtons";
import { guarantorButtonLabel } from "../../constants/buttonLabelName";
import { guarantorRoutes } from "../../constants/subMenuRoutes";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(0, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(1),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },

  paperTable: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(0),
  },
  customTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    margin: theme.spacing(0, 0, 4, 0),
  },
  customTableTdLeft: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "300px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRight: {
    border: "1px solid black",
    borderCollapse: "collapse",
    // width: "200px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  companyTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(4),
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  headerEmployee: {
    position: "relative",
    margin: theme.spacing(0, 0, 10, 0),
  },
  headerGuarantor: { position: "relative", margin: theme.spacing(0, 0, 20, 0) },
  profileImg: {
    position: "absolute",
    top: "8px",
    right: "16px",
    height: "120px",
    weight: "120px",
    border: "1px solid #0000000f",
  },
  signatureImg: {
    height: "40px",
    weight: "40px",
  },
  tableTitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  searchErrorMsg: {
    color: "red",
  },
  resultTable: {
    padding: theme.spacing(0, 0, 8, 0),
  },
}));
function GuarantorSearch() {
  const classes = useStyles();

  const { control, register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "Employee ID",
      field: "guarantor_employee_id",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
        marginBottom: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Guarantor Name",
      field: "guarantor_full_name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile NO.",
      field: "guarantor_mobile_no",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Present Address",
      field: "guarantor_present_address",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchError, setSearchError] = useState("");

  const onSubmit = async (data) => {
    // console.log(JSON.stringify(data));

    if (
      data.guarantor_employee_id === "" &&
      data.guarantor_full_name === "" &&
      data.guarantor_mobile_no === ""
    ) {
      setData([]);
      setSearchError(
        "* Employee ID or Guarantor Name or Guarantor Mobile NO Required!"
      );
    } else {
      const res = await API.post("/guarantors/search", data);
      if (res.success === 1) {
        console.log("RES: " + JSON.stringify(res.data));
        setData(res.data);
        setSearchError("");
      }
    }
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={guarantorRoutes}
        buttonLabelName={guarantorButtonLabel}
      />
      <h1>Search Guarantor</h1>
      <Paper className={classes.paperTable}>
        <List className={classes.root}>
          {/* search input field */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container>
              <Grid item xs={12}>
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    {/* Employee ID */}
                    <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="guarantor_employee_id"
                        variant="outlined"
                        placeholder="Employee ID"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_employee_id"
                      />
                    </Grid>
                    {errors.guarantor_employee_id && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_employee_id?.message}
                        </span>
                      </Grid>
                    )}
                    {/* Guarantor Full Name */}
                    <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="guarantor_full_name"
                        variant="outlined"
                        placeholder="Guarantor Full Name"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_full_name"
                      />
                    </Grid>
                    {errors.guarantor_full_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_full_name?.message}
                        </span>
                      </Grid>
                    )}
                    {/* Guarantor Mobile Number */}
                    <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="guarantor_mobile_no"
                        variant="outlined"
                        placeholder="Guarantor Mobile Number"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_mobile_no"
                      />
                    </Grid>
                    {errors.guarantor_mobile_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_mobile_no?.message}
                        </span>
                      </Grid>
                    )}
                    {/* Search Button */}
                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SearchOutlinedIcon />}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item xs={12}>
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <span className={classes.searchErrorMsg}>
                      {searchError}
                    </span>
                  </Grid>
                </ListItem>
              </Grid>
            </Grid>
          </form>
        </List>
      </Paper>

      <h1>{data.length} Records Found</h1>
      <Paper className={classes.resultTable}>
        <MaterialTable
          title=""
          columns={columns}
          data={data}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          options={{
            exportButton: false,
            search: false,
            paging: false,
            actionsColumnIndex: -1,
            headerStyle: {
              textAlign: "left",
              paddingRight: "32px",
            },

            actionsCellStyle: {
              paddingRight: "32px",
              justifyContent: "center",
            },
          }}
          actions={[
            {
              icon: () => <VisibilityOutlinedIcon />,
              tooltip: "View Details",
              onClick: (event, rowData) => {
                // console.log("ID " + rowData.id_pk);
                history.push("/guarantorView", {
                  id: `${rowData.guarantor_id_pk}`,
                });
                // history.push("/employeeView", { id: 50 });
              },
            },
            {
              icon: () => <EditOutlinedIcon />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                // console.log("ID " + rowData.id_pk);
                history.push("/guarantoUpdate", {
                  id: `${rowData.guarantor_id_pk}`,
                });
                // history.push("/employeeView", { id: 50 });
              },
            },
            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) => {},
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
        />
      </Paper>
    </main>
  );
}

export default withRouter(GuarantorSearch);
