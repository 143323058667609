import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import API from "../../common/api";
import { useLocation } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));
function EmployeeJoiningSalaryUpdate() {
  const classes = useStyles();

  //   GET BY ID----START
  const location = useLocation();
  //   console.log("location: " + JSON.stringify(location));
  //   console.log("ID: " + location.state.id);

  const id = parseInt(location.state.id);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await API.get(`/salary/getJoiningSalaryByPkId/${id}`);

      if (res.data) {
        setData(res.data);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  //   GET BY ID----END

  const { control, register, handleSubmit, errors } = useForm();

  const history = useHistory();

  const onSubmit = async (data) => {
    console.log(JSON.stringify(data));

    const res = await API.post(`/salary/updateJoiningSalary/${id}`, data);

    if (res.success === 1) {
      history.push("/employee");
    }
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />
      <h1>Update Joining Salary Info</h1>
      <Paper className={classes.paper}>
        <List className={classes.root}>
          {/* autoComplete="off" */}
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container>
                <Grid item xs={6}>
                  {/* Employee ID */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_employee_id"
                          className={classes.labelText}
                        >
                          Employee ID:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_employee_id"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            disabled: true,
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register({
                            required: "This Field is Required",
                          })}
                          name="salary_info_employee_id"
                          defaultValue={data.salary_info_employee_id}
                        />
                      </Grid>
                      {errors.salary_info_employee_id && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_employee_id?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Designation */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_designation"
                          className={classes.labelText}
                        >
                          Designation:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_designation"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_designation"
                          defaultValue={data.salary_info_designation}
                        />
                      </Grid>
                      {errors.salary_info_designation && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_designation?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Salary Type */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_salary_type"
                          className={classes.labelText}
                        >
                          Salary Type:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_salary_type"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            // disabled: true,
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          defaultValue={data.salary_info_salary_type}
                          inputRef={register}
                          name="salary_info_salary_type"
                        />
                      </Grid>
                      {errors.salary_info_salary_type && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_salary_type?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/*  IF SALARY TYPE SELECTED THEN BELOW 2 INPUT FIELD WILL SHOW ELSE HIDE */}

                  {/* Joining Salary Date */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_joining_salary_date"
                          className={classes.labelText}
                        >
                          Joining Salary Date:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            name="salary_info_joining_salary_date"
                            control={control}
                            initialFocusedDate={null}
                            defaultValue={null}
                            rules={{ required: "This Field is Required" }}
                            render={({ ref, ...rest }) => (
                              <KeyboardDatePicker
                                className={classes.textFieldInput}
                                fullWidth
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                {...rest}
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      {errors.salary_info_joining_salary_date && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_joining_salary_date?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Basic */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_basic"
                          className={classes.labelText}
                        >
                          Basic:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_basic"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_basic"
                          defaultValue={data.salary_info_basic}
                        />
                      </Grid>
                      {errors.salary_info_basic && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_basic?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* House Rent */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_house_rent"
                          className={classes.labelText}
                        >
                          House Rent:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_house_rent"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_house_rent"
                          defaultValue={data.salary_info_house_rent}
                        />
                      </Grid>
                      {errors.salary_info_house_rent && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_house_rent?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Transport */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_transport"
                          className={classes.labelText}
                        >
                          Transport:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_transport"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_transport"
                          defaultValue={data.salary_info_transport}
                        />
                      </Grid>
                      {errors.salary_info_transport && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_transport?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Medical */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_medical"
                          className={classes.labelText}
                        >
                          Medical:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_medical"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_medical"
                          defaultValue={data.salary_info_medical}
                        />
                      </Grid>
                      {errors.salary_info_medical && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_medical?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Utilities */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_utilities"
                          className={classes.labelText}
                        >
                          Utilities:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_utilities"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_utilities"
                          defaultValue={data.salary_info_utilities}
                        />
                      </Grid>
                      {errors.salary_info_utilities && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_utilities?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Salary */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_salary"
                          className={classes.labelText}
                        >
                          Salary:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_salary"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_salary"
                          defaultValue={data.salary_info_salary}
                        />
                      </Grid>
                      {errors.salary_info_salary && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_salary?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </Grid>
                <Grid item xs={6}>
                  {/* Fixed Overtime Allowance */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_fixed_overtime_allowance"
                          className={classes.labelText}
                        >
                          Fixed Overtime Allowance:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_fixed_overtime_allowance"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_fixed_overtime_allowance"
                          defaultValue={
                            data.salary_info_fixed_overtime_allowance
                          }
                        />
                      </Grid>
                      {errors.salary_info_fixed_overtime_allowance && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            *{" "}
                            {
                              errors.salary_info_fixed_overtime_allowance
                                ?.message
                            }
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Mobile Allowance */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_mobile_allowance"
                          className={classes.labelText}
                        >
                          Mobile Allowance:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_mobile_allowance"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_mobile_allowance"
                          defaultValue={data.salary_info_mobile_allowance}
                        />
                      </Grid>
                      {errors.salary_info_mobile_allowance && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_mobile_allowance?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>

                  {/* Night Allowance */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_night_allowance"
                          className={classes.labelText}
                        >
                          Night Allowance:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_night_allowance"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_night_allowance"
                          defaultValue={data.salary_info_night_allowance}
                        />
                      </Grid>
                      {errors.salary_info_night_allowance && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_night_allowance?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* Labor Cost */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_labor_cost"
                          className={classes.labelText}
                        >
                          Labor Cost:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_labor_cost"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_labor_cost"
                          defaultValue={data.salary_info_labor_cost}
                        />
                      </Grid>
                      {errors.salary_info_labor_cost && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_labor_cost?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* Motor Cycle Allowance */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_motorcycle_allowance"
                          className={classes.labelText}
                        >
                          Motor Cycle Allowance:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_motorcycle_allowance"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_motorcycle_allowance"
                          defaultValue={data.salary_info_motorcycle_allowance}
                        />
                      </Grid>
                      {errors.salary_info_motorcycle_allowance && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_motorcycle_allowance?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* Motor Cycle Maintenance Allowance including of fuel Mobil servicing etc. */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_motorcycle_maintenance_allowance"
                          className={classes.labelText}
                        >
                          Motor Cycle Maintenance Allowance including of fuel
                          Mobil servicing etc.:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_motorcycle_maintenance_allowance"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_motorcycle_maintenance_allowance"
                          defaultValue={
                            data.salary_info_motorcycle_maintenance_allowance
                          }
                        />
                      </Grid>
                      {errors.salary_info_motorcycle_maintenance_allowance && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            *{" "}
                            {
                              errors
                                .salary_info_motorcycle_maintenance_allowance
                                ?.message
                            }
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* Fixed Conveyance bill for documents pickup and delivery cost per month */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_fixed_conveyance_bill"
                          className={classes.labelText}
                        >
                          Fixed Conveyance bill for documents pickup and
                          delivery cost per month:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_fixed_conveyance_bill"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_fixed_conveyance_bill"
                          defaultValue={data.salary_info_fixed_conveyance_bill}
                        />
                      </Grid>
                      {errors.salary_info_fixed_conveyance_bill && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            *{" "}
                            {errors.salary_info_fixed_conveyance_bill?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* NCS Conveyance */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_ncs_conveyance"
                          className={classes.labelText}
                        >
                          NCS Conveyance:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_ncs_conveyance"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_ncs_conveyance"
                          defaultValue={data.salary_info_ncs_conveyance}
                        />
                      </Grid>
                      {errors.salary_info_ncs_conveyance && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_ncs_conveyance?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* Office Rent */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_office_rent"
                          className={classes.labelText}
                        >
                          Office Rent:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_office_rent"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_office_rent"
                          defaultValue={data.salary_info_office_rent}
                        />
                      </Grid>
                      {errors.salary_info_office_rent && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_office_rent?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                  {/* Stationary */}
                  <ListItem className={classes.inputRow}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salary_info_stationary"
                          className={classes.labelText}
                        >
                          Stationary:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="salary_info_stationary"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: classes.textFieldInput,
                            },
                          }}
                          inputRef={register}
                          name="salary_info_stationary"
                          defaultValue={data.salary_info_stationary}
                        />
                      </Grid>
                      {errors.salary_info_stationary && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.salary_info_stationary?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                </Grid>
                {/* Save Button */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
            </form>
          )}
        </List>
      </Paper>
    </main>
  );
}
export default withRouter(EmployeeJoiningSalaryUpdate);
