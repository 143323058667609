import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { format } from "date-fns";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import API from "../../common/api";
import { exportGuarantorViewToExcel } from "../../common/fileExport";
import SubmenuButtons from "../../components/SubmenuButtons";
import { guarantorButtonLabel } from "../../constants/buttonLabelName";
import { SERVER_URL } from "../../constants/constants";
import { guarantorRoutes } from "../../constants/subMenuRoutes";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(1),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  customTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    margin: theme.spacing(0, 0, 4, 0),
  },
  customTableTdLeft: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "300px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRight: {
    border: "1px solid black",
    borderCollapse: "collapse",
    // width: "200px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  companyTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(4),
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  headerEmployee: {
    position: "relative",
    margin: theme.spacing(0, 0, 15, 0),
  },
  headerGuarantor: { position: "relative", margin: theme.spacing(0, 0, 5, 0) },
  profileImg: {
    position: "absolute",
    top: "8px",
    right: "16px",
    height: "120px",
    weight: "120px",
    border: "1px solid #0000000f",
  },
  signatureImg: {
    height: "40px",
    weight: "40px",
  },
  tableTitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  empViewPage: {
    margin: theme.spacing(4),
  },
}));

function GuarantorView() {
  const classes = useStyles();

  // btn download start---------------------------------
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  // btn download end--------------------------------

  const location = useLocation();
  //   console.log("location: " + JSON.stringify(location));
  console.log("ID: " + location.state.id);

  const id = parseInt(location.state.id);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const res = await API.get(`guarantors/${id}`);
      if (res.data) {
        setData(res.data);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const exportGuarantorViewToPDF = (filename) => {
    // let doc = new jsPDF("p", "pt", "a4");
    // doc.html(document.querySelector("#content"), {
    //   callback: function (pdf) {
    //     let pageCount = doc.internal.getNumberOfPages();
    //     pdf.deletePage(pageCount);
    //     pdf.save(filename);
    //   },
    // });

    // html2canvas(document.querySelector("#content"), { useCORS: true }).then(
    //   (canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     const pdf = new jsPDF();
    //     const imgProps = pdf.getImageProperties(imgData);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    //     pdf.save(filename);
    //   }
    // );

    html2canvas(document.querySelector("#content"), { useCORS: true }).then(
      (canvas) => {
        // Few necessary setting options

        const contentDataURL = canvas.toDataURL("image/png");
        var margin = 8;
        var imgWidth = 210 - 2 * margin;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(
          contentDataURL,
          "PNG",
          margin,
          position,
          imgWidth,
          imgHeight
        );

        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(
            contentDataURL,
            "PNG",
            margin,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }
        doc.save(filename);
      }
    );
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={guarantorRoutes}
        buttonLabelName={guarantorButtonLabel}
      />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Paper className={classes.paper}>
          {/* download option start */}
          <Box
            display="flex"
            justifyContent="flex-end"
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleDownloadClick}
              size="small"
              title="Download"
            >
              <GetAppOutlinedIcon color="primary" />
            </IconButton>
            <div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleDownloadClose}
              >
                <MenuItem
                  onClick={() => {
                    exportGuarantorViewToExcel(data, data.guarantor_full_name);
                    handleDownloadClose();
                  }}
                >
                  Excel File
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exportGuarantorViewToPDF(data.guarantor_full_name);
                    handleDownloadClose();
                  }}
                >
                  PDF File
                </MenuItem>
              </Menu>
            </div>
          </Box>
          {/* download option end */}
          <div className={classes.empViewPage} id="content">
            {/* employee header section start */}
            <div className={classes.headerEmployee}>
              <h1 className={classes.companyTitle}>Express One Limited</h1>
              {data.guarantor_image && (
                <img
                  className={classes.profileImg}
                  src={SERVER_URL + data.guarantor_image}
                />
              )}
            </div>
            {/* employee header section end */}
            {/* guarantor header section start */}
            <div className={classes.headerGuarantor}></div>
            {/* guarantor header section end */}
            <p className={classes.tableTitle}>Guarantor Information</p>
            {/* guarantor table start */}
            <table className={classes.customTable}>
              <tr>
                <td className={classes.customTableTdLeft}>Employee ID</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_employee_id}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>
                  Guarantor full name
                </td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_full_name}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Father name</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_father_name}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Present Address</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_present_address}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Permanent Address</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_permanent_address}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Profession</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_profession}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Institute name</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_institute_name}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>
                  Address of institute
                </td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_address_of_institute}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Mobile number</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_mobile_no}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Date of Birth</td>
                {data.guarantor_dob ? (
                  <td className={classes.customTableTdRight}>
                    {format(new Date(data.guarantor_dob), "dd-MM-yyyy")}
                  </td>
                ) : null}
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>
                  Relation with candidate
                </td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_rel_with_candidate}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>Age</td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_age}
                </td>
              </tr>
              <tr>
                <td className={classes.customTableTdLeft}>
                  Guarantor signature
                </td>
                <td className={classes.customTableTdRight}>
                  {data.guarantor_signature && (
                    <img
                      className={classes.signatureImg}
                      src={SERVER_URL + data.guarantor_signature}
                    />
                  )}
                </td>
              </tr>
            </table>
            {/* guarantor table end */}
          </div>
        </Paper>
      )}
    </main>
  );
}

export default withRouter(GuarantorView);
