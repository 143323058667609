import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import SubmenuButtons from "../../components/SubmenuButtons";
import { leaveInfoButtonLabel } from "../../constants/buttonLabelName";
import { AXIOS_BASE_URL } from "../../constants/constants";
import { leaveInfoRoutes } from "../../constants/subMenuRoutes";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(0, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 2),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));
function LeaveInfo() {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = async (data) => {
    console.log(JSON.stringify(data));

    console.log("calling pdf.....");

    // http://expressoneltd.com/api  http://localhost:4000/api

    setIsLoading(true);
    axios(
      `${AXIOS_BASE_URL}/employeeLeaveInfoPdfReport/${data.employee_id_leave_info}/${data.year_leave_info}`,
      {
        method: "GET",
        responseType: "blob",
        //Force to receive data in a Blob Format
      }
    )
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    const year = new Date().getFullYear();
    const years = Array.from(new Array(20), (val, index) => index + year);
    setYearList(years);
  }, []);

  const [yearList, setYearList] = useState([]);

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={leaveInfoRoutes}
        buttonLabelName={leaveInfoButtonLabel}
      />

      <div>
        {/* SEARCH EMPLOYEES FILES START */}
        <h1>Print Leave Report</h1>
        {/* ADD UNIT NAME & DESIGNATION START */}
        <Paper className={classes.paperTable}>
          <List className={classes.root}>
            {/* search input field */}

            <Grid container>
              <Grid item xs={12}>
                {/* autoComplete="off" */}
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Grid container>
                    <Grid item>
                      {/* Employee ID */}
                      <ListItem className={classes.inputRow}>
                        <Grid container alignItems="center">
                          <Grid item xs={6}>
                            <InputLabel
                              htmlFor="employee_id_leave_info"
                              className={classes.labelText}
                            >
                              Employee ID:
                            </InputLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="employee_id_leave_info"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                classes: {
                                  input: classes.textFieldInput,
                                },
                              }}
                              inputRef={register({
                                required: "This Field is Required",
                              })}
                              name="employee_id_leave_info"
                            />
                          </Grid>
                          {errors.employee_id_leave_info && (
                            <Grid item xs={12} className={classes.errorDiv}>
                              <span className={classes.errorMessage}>
                                * {errors.employee_id_leave_info?.message}
                              </span>
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>

                      {/* Year */}
                      <ListItem className={classes.inputRow}>
                        <Grid container alignItems="center">
                          <Grid item xs={6}>
                            <InputLabel
                              htmlFor="year_leave_info"
                              className={classes.labelText}
                            >
                              Year:
                            </InputLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <select
                              className={classes.dropDown}
                              name="year_leave_info"
                              defaultValue=""
                              ref={register({
                                required: "This Field is Required",
                              })}
                            >
                              <option value="" disabled>
                                Select Year
                              </option>
                              {yearList &&
                                yearList.map((d, index) => {
                                  return (
                                    <option value={d} key={index}>
                                      {d}
                                    </option>
                                  );
                                })}
                            </select>
                          </Grid>
                          {errors.year_leave_info && (
                            <Grid item xs={12} className={classes.errorDiv}>
                              <span className={classes.errorMessage}>
                                * {errors.year_leave_info?.message}
                              </span>
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>
                    </Grid>

                    {/* Save Button */}
                    <ListItem className={classes.inputRow}>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </List>
        </Paper>
        {/* ADD UNIT NAME & DESIGNATION END */}
      </div>
    </main>
  );
}

export default withRouter(LeaveInfo);
