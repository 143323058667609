import { makeStyles } from "@material-ui/core/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import API from "../../common/api";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import ResignInfoEmployeeSearch from "./ResignInfoEmployeeSearch";
import SearchResignedEmployee from "./SearchResignedEmployee";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResignInfo() {
  const classes = useStyles();
  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "SL",
      field: "sl_no",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Employee ID",
      field: "employee_id",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile NO.",
      field: "mobile_no",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Email",
      field: "email",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
    {
      title: "Left Job?",
      field: "employee_is_left",
      cellStyle: {
        textAlign: "left",
        fontWeight: "bold",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDisableBtn, setIsDisableBtn] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await API.get("/users/get-users-left-job/");

      // --------
      const newState = res.data.map((obj) =>
        obj.employee_is_left === 1
          ? { ...obj, employee_is_left: "YES" }
          : { ...obj }
      );

      setData(newState);
    }
    fetchData();
  }, []);

  const deleteEmployeeById = async (employee_id, name) => {
    alert(`Employee ID - ${employee_id} [${name}]\nAll data will be deleted!`);
    const res = await API.delete(`/users/${employee_id}`);
    if (res.success === 1) {
      history.go(0);
    }
    setIsDisableBtn(true);
  };

  const getEmployeeListPDF = async (columns, data) => {
    const doc = new jsPDF();

    const columnTitles = columns.map((columnDef) => {
      return columnDef.title;
    });

    const pdfData = data.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );

    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
    });

    doc.save(`EmployeeList.pdf`);
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />

      <ResignInfoEmployeeSearch />

      <h1>Resigned Employee</h1>
      <SearchResignedEmployee />
      <br />

      <h1>Resigned Employee List</h1>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => {
            getEmployeeListPDF(columns, data);
          },
          search: false,
          pageSize: 20,
          pageSizeOptions: [50, 100, 200],
          actionsColumnIndex: -1,
          headerStyle: {
            textAlign: "left",
            paddingRight: "32px",
          },

          actionsCellStyle: {
            paddingRight: "32px",
            justifyContent: "center",
          },
        }}
        actions={
          [
            // {
            //   icon: () => <VisibilityOutlinedIcon />,
            //   tooltip: "View Details",
            //   onClick: (event, rowData) => {
            //     // console.log("ID " + rowData.id_pk);
            //     history.push("/employeeView", { id: `${rowData.employee_id}` });
            //     // history.push("/employeeView", { id: 50 });
            //   },
            // },
            // {
            //   icon: () => <EditOutlinedIcon />,
            //   tooltip: "Edit",
            //   onClick: (event, rowData) => {
            //     // console.log("ID " + rowData.id_pk);
            //     history.push("/employeeUpdate", { id: `${rowData.employee_id}` });
            //     // history.push("/employeeView", { id: 50 });
            //   },
            // },
            // {
            //   icon: () => <DeleteOutlineOutlinedIcon />,
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) => {
            //     // console.log("ID " + rowData.id_pk);
            //     // history.push("/employeeUpdate", { id: `${rowData.employee_id}` });
            //     // history.push("/employeeView", { id: 50 });
            //     if (!isDisableBtn) {
            //       deleteEmployeeById(rowData.employee_id, rowData.name);
            //     }
            //   },
            // },
          ]
        }
      />
    </main>
  );
}

export default withRouter(ResignInfo);
