import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import API from "../../common/api";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import SearchComponent from "./SearchComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(0, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));
function EmployeeSalaryAdd() {
  const classes = useStyles();
  const { control, register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const watchSalaryInfoSalaryType = watch("salary_info_salary_type", false);

  const [columns, setColumns] = useState([
    {
      title: "Sl",
      field: "id",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
        marginBottom: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "File Name",
      field: "file_name",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([
    { id: "01", file_name: "Profile Picture.jpg" },
    { id: "02", file_name: "TestFile.doc" },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchError, setSearchError] = useState("");

  const [fileDB, setFileDB] = useState("");

  const onSubmitFileUpload = async (data) => {
    // console.log(JSON.stringify(data));

    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      // console.log(key, value);
      formData.set(key, value);
    }
    formData.set("file_info_file", fileDB);

    const res = await API.post("/users/userFileUpload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.success === 1) {
      history.go(0);
    }
  };

  const fileHandler = (e) => {
    setFileDB(e.target.files[0]);
  };

  const onSubmitSearch = async (data) => {
    console.log(JSON.stringify(data));
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />

      <h1>Employee File Upload</h1>
      <Paper className={classes.paper}>
        <List className={classes.root}>
          {/* autoComplete="off" */}
          <form onSubmit={handleSubmit(onSubmitFileUpload)} autoComplete="off">
            <Grid container>
              <Grid item xs={6}>
                {/* Employee ID */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="file_info_employee_id"
                        className={classes.labelText}
                      >
                        Employee ID:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="file_info_employee_id"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="file_info_employee_id"
                      />
                    </Grid>
                    {errors.file_info_employee_id && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.file_info_employee_id?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* File Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="file_info_name"
                        className={classes.labelText}
                      >
                        File Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="file_info_name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="file_info_name"
                      />
                    </Grid>
                    {errors.file_info_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.file_info_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>

                {/* File */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="salary_info_salary_type"
                        className={classes.labelText}
                      >
                        File:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <input
                          type="file"
                          name="file_info_file"
                          id="file_info_file"
                          onChange={(e) => fileHandler(e)}
                        />
                      </FormControl>
                    </Grid>
                    {errors.file_info_file && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.file_info_file?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Upload Button */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<CloudUploadOutlinedIcon />}
                      >
                        Upload
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
            </Grid>
          </form>
        </List>
      </Paper>

      <SearchComponent />
    </main>
  );
}

export default withRouter(EmployeeSalaryAdd);
