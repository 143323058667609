import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import API from "../../common/api";
import { exportEmployeeViewToExcel } from "../../common/fileExport";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { AXIOS_BASE_URL, SERVER_URL } from "../../constants/constants";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { format } from "date-fns";
import axios from "axios";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import GuarantorSearch from "./../guarantor/GuarantorSearch";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(1),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  customTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    margin: theme.spacing(0, 0, 4, 0),
  },
  customTableTdLeft: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "450px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdLeftSum: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "450px",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "16px",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRightSum: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "450px",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "16px",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdMiddle: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRight: {
    border: "1px solid black",
    borderCollapse: "collapse",
    // width: "200px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  companyTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(4),
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  headerEmployee: {
    position: "relative",
    margin: theme.spacing(0, 0, 10, 0),
  },
  headerGuarantor: { position: "relative", margin: theme.spacing(0, 0, 20, 0) },
  profileImg: {
    position: "absolute",
    top: "8px",
    right: "16px",
    height: "120px",
    weight: "120px",
    border: "1px solid #0000000f",
  },
  signatureImg: {
    height: "40px",
    weight: "40px",
  },
  tableTitle: {
    // textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  empViewPage: {
    margin: theme.spacing(4),
  },
}));

function EmployeeView() {
  const classes = useStyles();
  const history = useHistory();

  // btn download start---------------------------------
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  // btn download end--------------------------------

  const location = useLocation();
  //   console.log("location: " + JSON.stringify(location));
  console.log("EmployeeView ID: " + location.state.id);

  const id = parseInt(location.state.id);

  const [data, setData] = useState([]);
  const [joiningSalaryData, setJoiningSalaryData] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableBtn, setIsDisableBtn] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const resSalary = await API.get(`/salary/${id}`);
      console.log("RES: " + JSON.stringify(resSalary));
      if (resSalary.data) {
        setJoiningSalaryData(resSalary.data.joiningSalaryRes);
        setSalaryData(resSalary.data.response);
      }

      const res = await API.get(`/users/getUserAndGuarantor/${id}`);
      if (res.data) {
        setData(res.data);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const getEmployeeDetailsPdf = async (employeeId) => {
    console.log("calling pdf.....");

    // http://expressoneltd.com/api  http://localhost:4000/api

    setIsLoading(true);
    axios(`${AXIOS_BASE_URL}/employeeDetailsPdfReport/${employeeId}`, {
      method: "GET",
      responseType: "blob",
      //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const appendZero = (i) => {
    if (i < 10) {
      return "0" + (i + 1).toString();
    }
    return i + 1;
  };

  const deleteGuarantorById = async (guarantor_id_pk) => {
    setIsDisableBtn(true);
    const res = await API.delete(
      `/guarantors/deleteByGuarantorId/${guarantor_id_pk}`
    );
    if (res.success === 1) {
      setIsDisableBtn(false);
      history.go(0);
    }
  };

  const deleteIncrementSalaryByPkId = async (id_pk) => {
    setIsDisableBtn(true);
    const res = await API.delete(
      `/salary/deleteIncrementSalaryByPkId/${id_pk}`
    );
    if (res.success === 1) {
      setIsDisableBtn(false);
      history.go(0);
    }
  };

  const deletePromotionalIncrementSalaryByPkId = async (id_pk) => {
    setIsDisableBtn(true);
    const res = await API.delete(
      `/salary/deletePromotionalIncrementSalaryByPkId/${id_pk}`
    );
    if (res.success === 1) {
      setIsDisableBtn(false);
      history.go(0);
    }
  };
  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Paper className={classes.paper}>
          {/* download option start */}

          <Box
            display="flex"
            justifyContent="flex-end"
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleDownloadClick}
              size="small"
              title="Download"
            >
              <GetAppOutlinedIcon color="primary" />
            </IconButton>
            <div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleDownloadClose}
              >
                <MenuItem
                  onClick={() => {
                    exportEmployeeViewToExcel(data, data[0].name);
                    handleDownloadClose();
                  }}
                >
                  Excel File
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // exportEmployeeViewToPDF(data[0].name);

                    getEmployeeDetailsPdf(data[0].employee_id);
                    handleDownloadClose();
                  }}
                >
                  PDF File
                </MenuItem>
              </Menu>
            </div>
          </Box>

          {/* download option end */}

          <div className={classes.empViewPage} id="content">
            {data[0] && (
              <>
                {/* employee header section start */}
                <div className={classes.headerEmployee}>
                  <h1 className={classes.companyTitle}>Express One Limited</h1>
                  <p className={classes.subTitle}>{data[0].name}</p>
                  <p className={classes.subTitle}>
                    {data[0].present_designation}
                  </p>
                  <p className={classes.subTitle}>
                    Employee ID: {data[0].employee_id}
                  </p>
                  {data[0].profile_image && (
                    <img
                      className={classes.profileImg}
                      src={SERVER_URL + data[0].profile_image}
                    />
                  )}
                </div>
                {/* employee header section end */}
                <p className={classes.tableTitle}>Personal Information</p>
                {/* employee table start */}
                <table className={classes.customTable}>
                  <tr>
                    <td className={classes.customTableTdLeft}>Employee ID</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].employee_id ? data[0].employee_id : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Employee Name</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].name ? data[0].name : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Personal file no.
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].personal_file_no
                        ? data[0].personal_file_no
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Present Designation
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].present_designation
                        ? data[0].present_designation
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Present Status
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].present_status ? data[0].present_status : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Mobile Number</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].mobile_no ? data[0].mobile_no : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Email</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].email ? data[0].email : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Present Address
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].present_address
                        ? data[0].present_address
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Permanent Address
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].permanent_address
                        ? data[0].permanent_address
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Academic Qualification
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].academic_qualification
                        ? data[0].academic_qualification
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Job Experience
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].job_experience ? data[0].job_experience : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.customTableTdLeft}>Father's Name</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].father_name ? data[0].father_name : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Mother's Name</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].mother_name ? data[0].mother_name : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Marital Status
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].marital_status ? data[0].marital_status : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      No. of children
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].no_of_children ? data[0].no_of_children : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Home District</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].home_district ? data[0].home_district : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Religion</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].religion ? data[0].religion : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Gender</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].gender ? data[0].gender : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Nationality</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].nationality ? data[0].nationality : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      National ID Card No.
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].nid ? data[0].nid : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Personal Identification No. of Birth Certificate
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].bid ? data[0].bid : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Blood Group</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].blood_group ? data[0].blood_group : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Passport No.</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].passport_no ? data[0].passport_no : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>E-Tin</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].e_tin ? data[0].e_tin : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Retirement Date
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].retirement_date
                        ? format(
                            new Date(data[0].retirement_date),
                            "dd-MM-yyyy"
                          )
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      PRL Start Date
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].prl_start_date
                        ? format(new Date(data[0].prl_start_date), "dd-MM-yyyy")
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Date of Birth</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].dob
                        ? format(new Date(data[0].dob), "dd-MM-yyyy")
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Date of Joining
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].doj
                        ? format(new Date(data[0].doj), "dd-MM-yyyy")
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Joining Designation
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].joining_designation
                        ? data[0].joining_designation
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Present Place of Posting
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].present_place_of_posting
                        ? data[0].present_place_of_posting
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Joining Date at Present Place
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].joining_date_at_present_place
                        ? format(
                            new Date(data[0].joining_date_at_present_place),
                            "dd-MM-yyyy"
                          )
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Last Promotion Date
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].last_promotion_date
                        ? format(
                            new Date(data[0].last_promotion_date),
                            "dd-MM-yyyy"
                          )
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Language(s) Known
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].language_known ? data[0].language_known : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Training Period
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].training_period
                        ? data[0].training_period
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Letter issue date
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].letter_issue_date
                        ? format(
                            new Date(data[0].letter_issue_date),
                            "dd-MM-yyyy"
                          )
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Duty Time</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].duty_time ? data[0].duty_time : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>Unit Name</td>
                    <td className={classes.customTableTdRight}>
                      {data[0].unit_name ? data[0].unit_name : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Employee Signature
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].signature && (
                        <img
                          className={classes.signatureImg}
                          src={SERVER_URL + data[0].signature}
                        />
                      )}
                      {data[0].signature == "" && "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Card Date of Issue
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].card_date_of_issue
                        ? format(
                            new Date(data[0].card_date_of_issue),
                            "dd-MM-yyyy"
                          )
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.customTableTdLeft}>
                      Card Validity Period
                    </td>
                    <td className={classes.customTableTdRight}>
                      {data[0].card_validity_period_start &&
                        data[0].card_validity_period_end && (
                          <>
                            {data[0].card_validity_period_start
                              ? format(
                                  new Date(data[0].card_validity_period_start),
                                  "dd-MM-yyyy"
                                )
                              : "N/A"}{" "}
                            To{" "}
                            {data[0].card_validity_period_end
                              ? format(
                                  new Date(data[0].card_validity_period_end),
                                  "dd-MM-yyyy"
                                )
                              : "N/A"}
                          </>
                        )}
                    </td>
                  </tr>
                </table>
                {/* employee table end */}
              </>
            )}

            {data.map(function (item, i) {
              return (
                <>
                  {item.guarantor_id_pk && (
                    <>
                      {/* guarantor header section start */}
                      {/* <div className={classes.headerGuarantor} key={i}>
                        {item.guarantor_image && (
                          <img
                            className={classes.profileImg}
                            src={SERVER_URL + item.guarantor_image}
                          />
                        )}
                      </div> */}
                      {/* guarantor header section end */}
                      <p className={classes.tableTitle}>
                        Guarantor - {appendZero(i)} -{" "}
                        <button
                          disabled={isDisableBtn}
                          onClick={() =>
                            deleteGuarantorById(item.guarantor_id_pk)
                          }
                        >
                          Delete
                        </button>
                      </p>
                      {/* guarantor table start */}
                      <table className={classes.customTable}>
                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Guarantor full name
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_full_name
                              ? item.guarantor_full_name
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Father name
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_father_name
                              ? item.guarantor_father_name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Present Address
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_present_address
                              ? item.guarantor_present_address
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Permanent Address
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_permanent_address
                              ? item.guarantor_permanent_address
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Profession
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_profession
                              ? item.guarantor_profession
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Institute name
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_institute_name
                              ? item.guarantor_institute_name
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Address of institute
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_address_of_institute
                              ? item.guarantor_address_of_institute
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Mobile number
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_mobile_no
                              ? item.guarantor_mobile_no
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Date of Birth
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_dob
                              ? format(
                                  new Date(item.guarantor_dob),
                                  "dd-MM-yyyy"
                                )
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Relation with candidate
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_rel_with_candidate
                              ? item.guarantor_rel_with_candidate
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>Age</td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_age ? item.guarantor_age : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td className={classes.customTableTdLeft}>
                            Guarantor signature
                          </td>
                          <td className={classes.customTableTdRight}>
                            {item.guarantor_signature && (
                              <img
                                className={classes.signatureImg}
                                src={SERVER_URL + item.guarantor_signature}
                              />
                            )}
                            {item.guarantor_signature == "" && "N/A"}
                          </td>
                        </tr>
                      </table>
                      {/* guarantor table end */}
                    </>
                  )}
                </>
              );
            })}

            {salaryData.map(function (salaryData, i) {
              return (
                <>
                  {salaryData.salary_info_employee_id && (
                    <>
                      {salaryData.salary_info_salary_type === "Increment" ? (
                        <p className={classes.tableTitle}>
                          Increment Date of (
                          {salaryData.salary_info_increment_salary_date
                            ? format(
                                new Date(
                                  salaryData.salary_info_increment_salary_date
                                ),
                                "dd-MM-yyyy"
                              )
                            : null}
                          ) -{" "}
                          <button
                            disabled={isDisableBtn}
                            onClick={() =>
                              deleteIncrementSalaryByPkId(salaryData.id_pk)
                            }
                          >
                            Delete
                          </button>
                        </p>
                      ) : null}

                      {salaryData.salary_info_salary_type ===
                      "Promotional Increment" ? (
                        <p className={classes.tableTitle}>
                          Promotional Increment Date of (
                          {salaryData.salary_info_promotional_increment_salary_date
                            ? format(
                                new Date(
                                  salaryData.salary_info_promotional_increment_salary_date
                                ),
                                "dd-MM-yyyy"
                              )
                            : null}
                          )-{" "}
                          <button
                            disabled={isDisableBtn}
                            onClick={() =>
                              deletePromotionalIncrementSalaryByPkId(
                                salaryData.id_pk
                              )
                            }
                          >
                            Delete
                          </button>
                        </p>
                      ) : null}

                      {/* increment salary table start */}
                      <table className={classes.customTable}>
                        {salaryData.salary_info_employee_id ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Employee ID
                            </td>
                            <td className={classes.customTableTdMiddle}></td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_employee_id}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_designation ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Designation
                            </td>
                            <td className={classes.customTableTdMiddle}></td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_designation}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_salary_type ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Salary Type
                            </td>
                            <td className={classes.customTableTdMiddle}></td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_salary_type}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_increment_salary_date ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Increment Salary Date
                            </td>
                            <td className={classes.customTableTdMiddle}></td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_increment_salary_date
                                ? format(
                                    new Date(
                                      salaryData.salary_info_increment_salary_date
                                    ),
                                    "dd-MM-yyyy"
                                  )
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_promotional_increment_salary_date ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Promotional Increment Salary Date
                            </td>
                            <td className={classes.customTableTdMiddle}></td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_promotional_increment_salary_date
                                ? format(
                                    new Date(
                                      salaryData.salary_info_promotional_increment_salary_date
                                    ),
                                    "dd-MM-yyyy"
                                  )
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_basic ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>Basic</td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_basic
                                ? salaryData.salary_info_basic
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_house_rent ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              House Rent
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_house_rent
                                ? salaryData.salary_info_house_rent
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_transport ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Transport
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_transport
                                ? salaryData.salary_info_transport
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_medical ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Medical
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_medical
                                ? salaryData.salary_info_medical
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_utilities ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Utilities
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_utilities
                                ? salaryData.salary_info_utilities
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_salary ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Salary
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_salary
                                ? salaryData.salary_info_salary
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_totalSalary ? (
                          <tr>
                            <td className={classes.customTableTdLeftSum}>
                              Total Salary
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRightSum}>
                              {salaryData.salary_info_totalSalary
                                ? salaryData.salary_info_totalSalary
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_fixed_overtime_allowance ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Fixed Overtime Allowance
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_fixed_overtime_allowance
                                ? salaryData.salary_info_fixed_overtime_allowance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_mobile_allowance ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Mobile Allowance
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_mobile_allowance
                                ? salaryData.salary_info_mobile_allowance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_night_allowance ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Night Allowance
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_night_allowance
                                ? salaryData.salary_info_night_allowance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_totalAllowance ? (
                          <tr>
                            <td className={classes.customTableTdLeftSum}>
                              Total Allowance
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRightSum}>
                              {salaryData.salary_info_totalAllowance
                                ? salaryData.salary_info_totalAllowance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_labor_cost ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Labor Cost
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_labor_cost
                                ? salaryData.salary_info_labor_cost
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_ncs_conveyance ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              NCS Conveyance
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_ncs_conveyance
                                ? salaryData.salary_info_ncs_conveyance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_office_rent ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Office Rent
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_office_rent
                                ? salaryData.salary_info_office_rent
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_stationary ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Stationary
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_stationary
                                ? salaryData.salary_info_stationary
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_totalOperatingCost ? (
                          <tr>
                            <td className={classes.customTableTdLeftSum}>
                              Total Operating Cost
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRightSum}>
                              {salaryData.salary_info_totalOperatingCost
                                ? salaryData.salary_info_totalOperatingCost
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_motorcycle_allowance ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Motor Cycle Allowance
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_motorcycle_allowance
                                ? salaryData.salary_info_motorcycle_allowance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_motorcycle_maintenance_allowance ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Motor Cycle Maintenance Allowance including of
                              fuel Mobil servicing etc.
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_motorcycle_maintenance_allowance
                                ? salaryData.salary_info_motorcycle_maintenance_allowance
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_fixed_conveyance_bill ? (
                          <tr>
                            <td className={classes.customTableTdLeft}>
                              Fixed Conveyance bill for documents pickup and
                              delivery cost per month
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRight}>
                              {salaryData.salary_info_fixed_conveyance_bill
                                ? salaryData.salary_info_fixed_conveyance_bill
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_totalOperatingCost2 ? (
                          <tr>
                            <td className={classes.customTableTdLeftSum}>
                              Total Operating Cost
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRightSum}>
                              {salaryData.salary_info_totalOperatingCost2
                                ? salaryData.salary_info_totalOperatingCost2
                                : null}
                            </td>
                          </tr>
                        ) : null}

                        {salaryData.salary_info_grossSalary ? (
                          <tr>
                            <td className={classes.customTableTdLeftSum}>
                              Gross Salary
                            </td>
                            <td className={classes.customTableTdMiddle}>Tk.</td>
                            <td className={classes.customTableTdRightSum}>
                              {salaryData.salary_info_grossSalary
                                ? salaryData.salary_info_grossSalary
                                : null}
                            </td>
                          </tr>
                        ) : null}
                      </table>
                      {/* increment salary table end */}
                    </>
                  )}
                </>
              );
            })}

            {joiningSalaryData.salary_info_employee_id && (
              <>
                {/* salary information start */}
                <p className={classes.tableTitle}>
                  Joining Date of (
                  {joiningSalaryData.salary_info_joining_salary_date
                    ? format(
                        new Date(
                          joiningSalaryData.salary_info_joining_salary_date
                        ),
                        "dd-MM-yyyy"
                      )
                    : null}
                  )-{" "}
                  <button
                    disabled={isDisableBtn}
                    onClick={() =>
                      history.push("/employeeJoiningSalaryUpdate", {
                        id: `${joiningSalaryData.id_pk}`,
                      })
                    }
                  >
                    Update
                  </button>
                </p>
                {/* joining salary table start */}
                <table className={classes.customTable}>
                  {joiningSalaryData.salary_info_employee_id ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Employee ID</td>
                      <td className={classes.customTableTdMiddle}></td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_employee_id}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_designation ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Designation</td>
                      <td className={classes.customTableTdMiddle}></td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_designation}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_salary_type ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Salary Type</td>
                      <td className={classes.customTableTdMiddle}></td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_salary_type}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_joining_salary_date ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Joining Salary Date
                      </td>
                      <td className={classes.customTableTdMiddle}></td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_joining_salary_date
                          ? format(
                              new Date(
                                joiningSalaryData.salary_info_joining_salary_date
                              ),
                              "dd-MM-yyyy"
                            )
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_basic ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Basic</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_basic
                          ? joiningSalaryData.salary_info_basic
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_house_rent ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>House Rent</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_house_rent
                          ? joiningSalaryData.salary_info_house_rent
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_transport ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Transport</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_transport
                          ? joiningSalaryData.salary_info_transport
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_medical ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Medical</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_medical
                          ? joiningSalaryData.salary_info_medical
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_utilities ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Utilities</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_utilities
                          ? joiningSalaryData.salary_info_utilities
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_salary ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Salary</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_salary
                          ? joiningSalaryData.salary_info_salary
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_totalSalary ? (
                    <tr>
                      <td className={classes.customTableTdLeftSum}>
                        Total Salary
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRightSum}>
                        {joiningSalaryData.salary_info_totalSalary
                          ? joiningSalaryData.salary_info_totalSalary
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_fixed_overtime_allowance ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Fixed Overtime Allowance
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_fixed_overtime_allowance
                          ? joiningSalaryData.salary_info_fixed_overtime_allowance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_mobile_allowance ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Mobile Allowance
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_mobile_allowance
                          ? joiningSalaryData.salary_info_mobile_allowance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_night_allowance ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Night Allowance
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_night_allowance
                          ? joiningSalaryData.salary_info_night_allowance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_totalAllowance ? (
                    <tr>
                      <td className={classes.customTableTdLeftSum}>
                        Total Allowance
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRightSum}>
                        {joiningSalaryData.salary_info_totalAllowance
                          ? joiningSalaryData.salary_info_totalAllowance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_labor_cost ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Labor Cost</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_labor_cost
                          ? joiningSalaryData.salary_info_labor_cost
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_ncs_conveyance ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        NCS Conveyance
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_ncs_conveyance
                          ? joiningSalaryData.salary_info_ncs_conveyance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_office_rent ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Office Rent</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_office_rent
                          ? joiningSalaryData.salary_info_office_rent
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_stationary ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>Stationary</td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_stationary
                          ? joiningSalaryData.salary_info_stationary
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_totalOperatingCost ? (
                    <tr>
                      <td className={classes.customTableTdLeftSum}>
                        Total Operating Cost
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRightSum}>
                        {joiningSalaryData.salary_info_totalOperatingCost
                          ? joiningSalaryData.salary_info_totalOperatingCost
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_motorcycle_allowance ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Motor Cycle Allowance
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_motorcycle_allowance
                          ? joiningSalaryData.salary_info_motorcycle_allowance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_motorcycle_maintenance_allowance ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Motor Cycle Maintenance Allowance including of fuel
                        Mobil servicing etc.
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_motorcycle_maintenance_allowance
                          ? joiningSalaryData.salary_info_motorcycle_maintenance_allowance
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_fixed_conveyance_bill ? (
                    <tr>
                      <td className={classes.customTableTdLeft}>
                        Fixed Conveyance bill for documents pickup and delivery
                        cost per month
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRight}>
                        {joiningSalaryData.salary_info_fixed_conveyance_bill
                          ? joiningSalaryData.salary_info_fixed_conveyance_bill
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_totalOperatingCost2 ? (
                    <tr>
                      <td className={classes.customTableTdLeftSum}>
                        Total Operating Cost
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRightSum}>
                        {joiningSalaryData.salary_info_totalOperatingCost2
                          ? joiningSalaryData.salary_info_totalOperatingCost2
                          : null}
                      </td>
                    </tr>
                  ) : null}

                  {joiningSalaryData.salary_info_grossSalary ? (
                    <tr>
                      <td className={classes.customTableTdLeftSum}>
                        Gross Salary
                      </td>
                      <td className={classes.customTableTdMiddle}>Tk.</td>
                      <td className={classes.customTableTdRightSum}>
                        {joiningSalaryData.salary_info_grossSalary
                          ? joiningSalaryData.salary_info_grossSalary
                          : null}
                      </td>
                    </tr>
                  ) : null}
                </table>
                {/* joining salary table end */}
              </>
            )}
          </div>
        </Paper>
      )}
    </main>
  );
}

export default withRouter(EmployeeView);
