import { Button, Card, makeStyles } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import React from "react";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
  },
  pageHeader: {
    margin: theme.spacing(2, 0, 0, 0),
    display: "flex",
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

export default function SubmenuButtons({ routes, buttonLabelName }) {
  const classes = useStyles();
  let history = useHistory();
  // console.log(JSON.stringify(routes));

  return (
    <div className={classes.pageHeader}>
      <Card className={classes.pageIcon}>
        {buttonLabelName.add && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<PersonAddOutlinedIcon />}
            onClick={() => {
              history.push(routes.add);
            }}
          >
            {buttonLabelName.add}
          </Button>
        )}

        {buttonLabelName.salaryInfo && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<LocalAtmOutlinedIcon />}
            onClick={() => {
              history.push(routes.salaryInfo);
            }}
          >
            {buttonLabelName.salaryInfo}
          </Button>
        )}

        {buttonLabelName.fileUpload && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<PublishOutlinedIcon />}
            onClick={() => {
              history.push(routes.fileUpload);
            }}
          >
            {buttonLabelName.fileUpload}
          </Button>
        )}

        {buttonLabelName.importBulk && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<ImportExportIcon />}
            onClick={() => {
              history.push(routes.importBulk);
            }}
          >
            {buttonLabelName.importBulk}
          </Button>
        )}

        {buttonLabelName.viewAll && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<ViewListOutlinedIcon />}
            onClick={() => {
              history.push(routes.viewAll);
            }}
          >
            {buttonLabelName.viewAll}
          </Button>
        )}

        {buttonLabelName.search && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SearchOutlinedIcon />}
            onClick={() => {
              history.push(routes.search);
            }}
          >
            {buttonLabelName.search}
          </Button>
        )}

        {buttonLabelName.setting && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SettingsOutlinedIcon />}
          >
            {buttonLabelName.setting}
          </Button>
        )}

        {buttonLabelName.idCard && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<PermContactCalendarOutlinedIcon />}
            onClick={() => {
              history.push(routes.idCard);
            }}
          >
            {buttonLabelName.idCard}
          </Button>
        )}

        {buttonLabelName.leaveInfo && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<PermContactCalendarOutlinedIcon />}
            onClick={() => {
              history.push(routes.leaveInfo);
            }}
          >
            {buttonLabelName.leaveInfo}
          </Button>
        )}
      </Card>
    </div>
  );
}
