import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import MaterialTable from "material-table";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { AXIOS_BASE_URL, SERVER_URL } from "../../constants/constants";

import API from "../../common/api";
import { setDayWithOptions } from "date-fns/fp";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(0, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));
function SearchComponent() {
  const classes = useStyles();

  const { control, register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const watchSalaryInfoSalaryType = watch("salary_info_salary_type", false);

  const [columns, setColumns] = useState([
    {
      title: "Sl",
      field: "id",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
        marginBottom: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Path",
      field: "file_info_path",
      hidden: true,
    },
    {
      title: "File Name",
      field: "file_info_name",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [dataFormSend, setDataFormSend] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const onSubmitSearch = async (data) => {
    console.log(JSON.stringify(data));

    if (data.employee_id === "" && data.name === "" && data.mobile_no === "") {
      setData([]);
      setSearchError("* Employee ID or Name or Mobile NO Required!");
    } else {
      const res = await API.post("/users/searchUserFiles", data);
      setDataFormSend(data);
      if (res.success === 1) {
        console.log("RES: " + JSON.stringify(res.data));
        setData(res.data);
        setSearchError("");
      }
    }
  };

  const downloadFileById = async (id_pk) => {
    // setIsDisableBtn(true);
    const res = await API.get(`/users/userFileDownloadById/${id_pk}`);
    console.log("res");
    console.log(res.file);
    //  `https://expressoneltd.com/api/users/userFileDownloadById/${id_pk}` live
    //uploads/userFiles/1/123-1637435552123-149477824.png
    if (res.success === 1) {
      window.open(res.file);
    }
  };
  const deleteFileById = async (id_pk) => {
    setIsDisableBtn(true);
    const res = await API.delete(`/users/deleteUserFile/${id_pk}`);
    if (data.length === 1) {
      history.go(0);
    }
    if (res) {
      setIsDisableBtn(false);
      onSubmitSearch(dataFormSend);
      // history.go(0);
    }
  };
  return (
    <div>
      {/* SEARCH EMPLOYEES FILES START */}
      <h1>Search Files</h1>

      <Paper className={classes.paperTable}>
        <List className={classes.root}>
          {/* search input field */}
          <form onSubmit={handleSubmit(onSubmitSearch)} autoComplete="off">
            <Grid container>
              <Grid item xs={12}>
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    {/* Employee ID */}
                    <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="employee_id"
                        variant="outlined"
                        placeholder="Employee ID"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="employee_id"
                      />
                    </Grid>
                    {errors.employee_id && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.employee_id?.message}
                        </span>
                      </Grid>
                    )}
                    {/* Employee Name */}
                    <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="name"
                        variant="outlined"
                        placeholder="Employee Name"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="name"
                      />
                    </Grid>
                    {errors.name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.name?.message}
                        </span>
                      </Grid>
                    )}
                    {/* Mobile Number */}
                    <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="mobile_no"
                        variant="outlined"
                        placeholder="Mobile Number"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="mobile_no"
                      />
                    </Grid>
                    {errors.mobile_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.mobile_no?.message}
                        </span>
                      </Grid>
                    )}
                    {/* Search Button */}
                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SearchOutlinedIcon />}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item xs={12}>
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <span className={classes.searchErrorMsg}>
                      {searchError}
                    </span>
                  </Grid>
                </ListItem>
              </Grid>
            </Grid>
          </form>
        </List>
      </Paper>

      <h1>{data.length} Files Found</h1>
      {/* SEARCH EMPLOYEES FILES END */}

      <Paper className={classes.resultTable}>
        <MaterialTable
          title=""
          columns={columns}
          data={data}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          options={{
            exportButton: false,
            search: false,
            paging: false,
            actionsColumnIndex: -1,
            headerStyle: {
              textAlign: "left",
              paddingRight: "32px",
            },

            actionsCellStyle: {
              paddingRight: "32px",
              justifyContent: "center",
            },
          }}
          actions={[
            {
              icon: () => <GetAppOutlinedIcon />,
              tooltip: "Download File",
              onClick: (event, rowData) => {
                downloadFileById(rowData.id_pk);
              },
            },
            {
              icon: () => <ClearOutlinedIcon />,
              tooltip: "Remove File",
              onClick: (event, rowData) => {
                deleteFileById(rowData.id_pk);
              },
            },
          ]}
        />
      </Paper>
    </div>
  );
}

export default SearchComponent;
