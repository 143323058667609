import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import API from "../../common/api";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import { saveAs } from "file-saver";
import { AXIOS_BASE_URL, SERVER_URL } from "../../constants/constants";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(0, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(1),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "left",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },

  paperTable: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(0),
  },
  customTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    margin: theme.spacing(0, 0, 4, 0),
  },
  customTableTdLeft: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "300px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRight: {
    border: "1px solid black",
    borderCollapse: "collapse",
    // width: "200px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  companyTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(4),
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  headerEmployee: {
    position: "relative",
    margin: theme.spacing(0, 0, 10, 0),
  },
  headerGuarantor: { position: "relative", margin: theme.spacing(0, 0, 20, 0) },
  profileImg: {
    position: "absolute",
    top: "8px",
    right: "16px",
    height: "120px",
    weight: "120px",
    border: "1px solid #0000000f",
  },
  signatureImg: {
    height: "40px",
    weight: "40px",
  },
  tableTitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  searchErrorMsg: {
    color: "red",
  },
  resultTable: {
    padding: theme.spacing(0, 0, 8, 0),
  },
}));

function EmployeeIdCard() {
  const classes = useStyles();

  const { control, register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "Employee ID",
      field: "employee_id",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
        marginBottom: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile NO.",
      field: "mobile_no",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Email",
      field: "email",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchError, setSearchError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    console.log(JSON.stringify(data));

    console.log("calling generateReport");

    setIsLoading(true);
    axios(`${AXIOS_BASE_URL}/idCard/${data.employee_id}`, {
      method: "GET",
      responseType: "blob",
      //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main className={classes.content}>
      {isLoading ? (
        <>
          <h1>Loading...</h1>
          <Loader
            type="Bars"
            color="#2A5298"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </>
      ) : (
        <>
          <SubmenuButtons
            routes={employeeRoutes}
            buttonLabelName={employeeButtonLabel}
          />
          <h1>Employee ID Card</h1>
          <Paper className={classes.paperTable}>
            <List className={classes.root}>
              {/* search input field */}
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container>
                  <Grid item xs={12}>
                    <ListItem className={classes.inputRow}>
                      <Grid container alignItems="center">
                        {/* Employee ID */}
                        <Grid item xs={3} className={classes.gridPadding}>
                          <TextField
                            id="employee_id"
                            variant="outlined"
                            placeholder="Employee ID"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textFieldInput,
                              },
                            }}
                            inputRef={register({
                              required: "This Field is Required",
                              pattern: {
                                value: /^[+]?\d+([.]\d+)?$/,
                                message: "Only Positive Numbers Allowed!",
                              },
                            })}
                            name="employee_id"
                          />
                        </Grid>

                        {/* Search Button */}
                        <Grid item xs={3}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                          >
                            Get ID Card
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItem className={classes.inputRow}>
                      {errors.employee_id && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.employee_id?.message}
                          </span>
                        </Grid>
                      )}
                    </ListItem>
                  </Grid>
                </Grid>
              </form>
            </List>
          </Paper>
        </>
      )}
    </main>
  );
}

export default withRouter(EmployeeIdCard);
