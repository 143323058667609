export const employeeButtonLabel = {
  add: "Add Employee Info",
  importBulk: "",
  viewAll: "Employee History List",
  search: "",
  setting: "",
  idCard: "ID Card",
  salaryInfo: "Add Salary Info",
  fileUpload: "Document Upload",
};

export const guarantorButtonLabel = {
  add: "Add Guarantor",
  importBulk: "",
  viewAll: "View All",
  search: "Search",
  setting: "",
};

export const leaveInfoButtonLabel = {
  add: "Add Leave Information",
  leaveInfo: "Print Leave Report",
};
