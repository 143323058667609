import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SubmenuButtons from "../../components/SubmenuButtons";
import { useForm, Controller } from "react-hook-form";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { guarantorRoutes } from "../../constants/subMenuRoutes";
import { guarantorButtonLabel } from "../../constants/buttonLabelName";
import API from "../../common/api";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
}));

function GuarantorAdd() {
  const classes = useStyles();
  const { control, register, handleSubmit, errors, watch } = useForm();
  const [picture, setPicture] = useState();
  const [pictureDB, setPictureDB] = useState("");
  const [signatureImg, setSignatureImg] = useState();
  const [signatureImgDB, setSignatureImgDB] = useState("");
  const history = useHistory();
  const onSubmit = async (data) => {
    //console.log(JSON.stringify(data));
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      console.log(key, value);
      formData.set(key, value);
    }
    formData.set("guarantor_imguarantor_age", pictureDB);
    formData.set("guarantor_signature", signatureImgDB);

    console.log(formData);

    const res = await API.post("/guarantors", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.success === 1) {
      history.push("/guarantor");
    }
  };

  const imageHandler = (e) => {
    const { name } = e.target;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (name === "guarantor_image") {
          setPicture(reader.result);
          setPictureDB(e.target.files[0]);
        } else {
          setSignatureImg(reader.result);
          setSignatureImgDB(e.target.files[0]);
        }
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={guarantorRoutes}
        buttonLabelName={guarantorButtonLabel}
      />
      <h1>Create New Guarantor</h1>
      <Paper className={classes.paper}>
        <List className={classes.root}>
          {/* autoComplete="off" */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container>
              <Grid item xs={6}>
                {/* Employee ID */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_employee_id"
                        className={classes.labelText}
                      >
                        * Employee ID:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_employee_id"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="guarantor_employee_id"
                      />
                    </Grid>
                    {errors.guarantor_employee_id && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_employee_id?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Guarantor Full Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_full_name"
                        className={classes.labelText}
                      >
                        * Guarantor Full Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_full_name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="guarantor_full_name"
                      />
                    </Grid>
                    {errors.guarantor_full_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_full_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* গ্যারান্টারের পূর্ণ নাম (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_full_name_bn"
                        className={classes.labelText}
                      >
                        গ্যারান্টারের পূর্ণ নাম (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_full_name_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_full_name_bn"
                      />
                    </Grid>
                    {errors.guarantor_full_name_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_full_name_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Father Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_father_name"
                        className={classes.labelText}
                      >
                        Father Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_father_name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_father_name"
                      />
                    </Grid>

                    {errors.guarantor_father_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_father_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* পিতার নাম (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_father_name_bn"
                        className={classes.labelText}
                      >
                        পিতার নাম (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_father_name_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_father_name_bn"
                      />
                    </Grid>
                    {errors.guarantor_father_name_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_father_name_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Present Address */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_present_address"
                        className={classes.labelText}
                      >
                        Present Address:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_present_address"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_present_address"
                      />
                    </Grid>
                    {errors.guarantor_present_address && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_present_address?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* বর্তমান ঠিকানা (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_present_address_bn"
                        className={classes.labelText}
                      >
                        বর্তমান ঠিকানা (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_present_address_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_present_address_bn"
                      />
                    </Grid>
                    {errors.guarantor_present_address_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_present_address_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Permanent Address */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_permanent_address"
                        className={classes.labelText}
                      >
                        Permanent Address:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_permanent_address"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_permanent_address"
                      />
                    </Grid>
                    {errors.guarantor_permanent_address && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_permanent_address?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* স্থায়ী ঠিকানা (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_permanent_address_bn"
                        className={classes.labelText}
                      >
                        স্থায়ী ঠিকানা (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_permanent_address_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_permanent_address_bn"
                      />
                    </Grid>
                    {errors.guarantor_permanent_address_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_permanent_address_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* guarantor_profession */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_profession"
                        className={classes.labelText}
                      >
                        Guarantor Profession:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_profession"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_profession"
                      />
                    </Grid>
                    {errors.guarantor_profession && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_profession?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* পেশা (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_profession_bn"
                        className={classes.labelText}
                      >
                        পেশা (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_profession_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_profession_bn"
                      />
                    </Grid>
                    {errors.guarantor_profession_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_profession_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Institute Name */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_institute_name"
                        className={classes.labelText}
                      >
                        Institute Name:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_institute_name"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_institute_name"
                      />
                    </Grid>
                    {errors.guarantor_institute_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_institute_name?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* প্রতিষ্ঠানের নাম (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_institute_name_bn"
                        className={classes.labelText}
                      >
                        প্রতিষ্ঠানের নাম (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_institute_name_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_institute_name_bn"
                      />
                    </Grid>
                    {errors.guarantor_institute_name_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_institute_name_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item xs={6}>
                {/* Address of Institute */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_address_of_institute"
                        className={classes.labelText}
                      >
                        Address of Institute:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_address_of_institute"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_address_of_institute"
                      />
                    </Grid>
                    {errors.guarantor_address_of_institute && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_address_of_institute?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* কর্মস্থান / ব্যাবসায়িক ঠিকানা (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_address_of_institute_bn"
                        className={classes.labelText}
                      >
                        কর্মস্থান / ব্যাবসায়িক ঠিকানা (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_address_of_institute_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_address_of_institute_bn"
                      />
                    </Grid>
                    {errors.guarantor_address_of_institute_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_address_of_institute_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Mobile Number */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_mobile_no"
                        className={classes.labelText}
                      >
                        Mobile Number:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_mobile_no"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_mobile_no"
                      />
                    </Grid>
                    {errors.guarantor_mobile_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_mobile_no?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* মোবাইল নাম্বার (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_mobile_no_bn"
                        className={classes.labelText}
                      >
                        মোবাইল নাম্বার (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_mobile_no_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_mobile_no_bn"
                      />
                    </Grid>
                    {errors.guarantor_mobile_no_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_mobile_no_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Date of Birth */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_dob"
                        className={classes.labelText}
                      >
                        Date of Birth:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Controller
                          name="guarantor_dob"
                          control={control}
                          initialFocusedDate={null}
                          defaultValue={null}
                          render={({ ref, ...rest }) => (
                            <KeyboardDatePicker
                              className={classes.textFieldInput}
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              maxDate={new Date()}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {errors.guarantor_dob && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_dob?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* জন্ম তারিখ (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_dob_bn"
                        className={classes.labelText}
                      >
                        জন্ম তারিখ (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_dob_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_dob_bn"
                      />
                    </Grid>
                    {errors.guarantor_dob_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_dob_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Relation with Candidate */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_rel_with_candidate"
                        className={classes.labelText}
                      >
                        Relation with Candidate:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_rel_with_candidate"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_rel_with_candidate"
                      />
                    </Grid>
                    {errors.guarantor_rel_with_candidate && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_rel_with_candidate?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* চাকুরি প্রার্থীর সাথে সম্পর্ক (বাংলায়) */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_rel_with_candidate_bn"
                        className={classes.labelText}
                      >
                        চাকুরি প্রার্থীর সাথে সম্পর্ক (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_rel_with_candidate_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_rel_with_candidate_bn"
                      />
                    </Grid>
                    {errors.guarantor_rel_with_candidate_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_rel_with_candidate_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* guarantor_age */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_age"
                        className={classes.labelText}
                      >
                        Guarantor Age:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_age"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_age"
                      />
                    </Grid>
                    {errors.guarantor_age && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_age?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* বয়স */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_age_bn"
                        className={classes.labelText}
                      >
                        বয়স (বাংলায়):
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="guarantor_age_bn"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="guarantor_age_bn"
                      />
                    </Grid>
                    {errors.guarantor_age_bn && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_age_bn?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Guarantor Signature */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_signature"
                        className={classes.labelText}
                      >
                        Guarantor Signature:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="img-holder">
                        <input
                          type="file"
                          accept="imguarantor_age/*"
                          name="guarantor_signature"
                          id="input"
                          onChange={(e) => imageHandler(e)}
                        />
                        <img
                          src={signatureImg}
                          alt=""
                          id="guarantor_signature"
                          className="img"
                        />
                      </div>
                    </Grid>
                    {errors.guarantor_signature && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_signature?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {/* Guarantor Image */}
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="guarantor_image"
                        className={classes.labelText}
                      >
                        Guarantor Image:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="img-holder">
                        <input
                          type="file"
                          accept="imguarantor_age/*"
                          name="guarantor_image"
                          id="input"
                          onChange={(e) => imageHandler(e)}
                        />
                        <img src={picture} alt="" id="img" className="img" />
                      </div>
                    </Grid>
                    {errors.guarantor_image && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.guarantor_image?.message}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </Grid>
              {/* Save Button */}
              <ListItem className={classes.inputRow}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          </form>
        </List>
      </Paper>
    </main>
  );
}

export default withRouter(GuarantorAdd);
