import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import DirectionsRunOutlinedIcon from "@material-ui/icons/DirectionsRunOutlined";
import React from "react";

export const menu = [
  {
    icon: <PeopleAltOutlinedIcon style={{ color: "#fff" }} />,
    title: "Employee",
    items: [
      {
        title: "Employee Info",
        path: "/employee",
        items: [],
      },
      {
        title: "Guarantor Info",
        path: "/guarantor",
        items: [],
      },
    ],
  },
  {
    icon: <AssessmentOutlinedIcon style={{ color: "#fff" }} />,
    title: "Leave Info",
    path: "/leaveInfo",
  },

  {
    icon: <DirectionsRunOutlinedIcon style={{ color: "#fff" }} />,
    title: "Resign Info",
    path: "/resignInfo",
  },

  {
    icon: <SettingsIcon style={{ color: "#fff" }} />,
    title: "Settings",
    path: "/settings",
  },
  // {
  //   icon: <LocalAtmOutlinedIcon style={{ color: "#fff" }} />,
  //   title: "Salary",
  //   items: [
  //     {
  //       title: "Salary Info",
  //       path: "/employee",
  //       items: [],
  //     },
  //   ],
  // },
];

// ORIGINAL EXAMPLE
// export const menu = [
//   {
//     icon: <HomeOutlinedIcon />,
//     title: "Home",
//     items: [],
//   },
//   {
//     icon: <LocalLibraryOutlinedIcon />,
//     title: "Education",
//     items: [
//       {
//         title: "Technical Analysis",
//         items: [
//           {
//             title: "The Dow Theory",
//             to: "/thedowtheory",
//           },
//           {
//             title: "Charts & Chart Patterns",
//             to: "/chart",
//           },
//           {
//             title: "Trend & Trend Lines",
//             to: "/trendlines",
//           },
//           {
//             title: "Support & Resistance",
//             to: "/sandr",
//           },
//         ],
//       },
//       {
//         title: "Fundamental Analysis",
//         items: [
//           {
//             title: "The Dow Theory",
//             to: "/thedowtheory",
//           },
//           {
//             title: "Charts & Chart Patterns",
//             to: "/chart",
//           },
//           {
//             title: "Trend & Trend Lines",
//             to: "/trendlines",
//           },
//           {
//             title: "Support & Resistance",
//             to: "/sandr",
//           },
//         ],
//       },
//       {
//         title: "Elliot Wave Analysis",
//         items: [
//           {
//             title: "The Dow Theory",
//             to: "/thedowtheory",
//           },
//           {
//             title: "Charts & Chart Patterns",
//             to: "/chart",
//           },
//           {
//             title: "Trend & Trend Lines",
//             to: "/trendlines",
//           },
//           {
//             title: "Support & Resistance",
//             to: "/sandr",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     icon: <TrendingUpOutlinedIcon />,
//     title: "Options",
//   },
//   {
//     icon: <DescriptionOutlinedIcon />,
//     title: "Blog",
//   },
// ];
