import {
  createMuiTheme,
  CssBaseline,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import Sidebar from ".././components/Sidebar";
import ResponseMessage from "../common/ResponseMessage";
import Employee from "../pages/employee/Employee";
import EmployeeAdd from "../pages/employee/EmployeeAdd";
import EmployeeIdCard from "../pages/employee/EmployeeIdCard";
import EmployeeImport from "../pages/employee/EmployeeImport";
import EmployeeSearch from "../pages/employee/EmployeeSearch";
import EmployeeUpdate from "../pages/employee/EmployeeUpdate";
import EmployeeView from "../pages/employee/EmployeeView";
import GuarantorAdd from "../pages/guarantor/GuarantorAdd";
import GuarantorImport from "../pages/guarantor/GuarantorImport";
import GuarantorUpdate from "../pages/guarantor/GuarantorUpdate";
import GuarantorView from "../pages/guarantor/GuarantorView";
import EmployeeFileUpload from "./../pages/employee/EmployeeFileUpload";
import EmployeeJoiningSalaryUpdate from "./../pages/employee/EmployeeJoiningSalaryUpdate";
import EmployeeSalaryAdd from "./../pages/employee/EmployeeSalaryAdd";
import Guarantor from "./../pages/guarantor/Guarantor";
import GuarantorSearch from "./../pages/guarantor/GuarantorSearch";
import Login from "./../pages/Login";
import "./App.css";
import ProtectedRoute from "./ProtectedRoute";
import LeaveInfo from "../pages/leaveInfo/LeaveInfo";
import Settings from "../pages/Settings";
import LeaveInfoAdd from "../pages/leaveInfo/LeaveInfoAdd";
import ResignInfo from "../pages/resignInfo/ResignInfo";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2a5298",
      light: "#3c44b126",
    },
    secondary: {
      main: "#253053",
      light: "#f8324526",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },

  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 11,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: theme.spacing(6, 2, 6, 2),
  },
}));
function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Login} />
          <div className={classes.root}>
            <Sidebar />
            {/* EMPLOYEE */}
            <ProtectedRoute path="/employee" component={Employee} />
            <ProtectedRoute path="/employeeAdd" component={EmployeeAdd} />
            <ProtectedRoute path="/employeeUpdate" component={EmployeeUpdate} />
            <ProtectedRoute path="/employeeView" component={EmployeeView} />
            <ProtectedRoute path="/employeeImport" component={EmployeeImport} />
            <ProtectedRoute path="/employeeSearch" component={EmployeeSearch} />
            <ProtectedRoute path="/employeeIdCard" component={EmployeeIdCard} />
            <ProtectedRoute
              path="/employeeFileUpload"
              component={EmployeeFileUpload}
            />
            {/* SALARY */}

            {/* <Route path="/employeeSalary" component={Guarantor} /> */}
            <ProtectedRoute
              path="/employeeSalaryAdd"
              component={EmployeeSalaryAdd}
            />
            <ProtectedRoute
              path="/employeeJoiningSalaryUpdate"
              component={EmployeeJoiningSalaryUpdate}
            />
            {/* <Route path="/employeeSalaryUpdate" component={employeeSalaryUpdate} />
          <Route path="/employeeSalaryView" component={employeeSalaryView} />
          <Route path="/employeeSalaryImport" component={employeeSalaryImport} />
          <Route path="/employeeSalarySearch" component={employeeSalarySearch} /> */}

            {/* GUARANTOR */}
            <ProtectedRoute path="/guarantor" component={Guarantor} />
            <ProtectedRoute path="/guarantorAdd" component={GuarantorAdd} />
            <ProtectedRoute
              path="/guarantoUpdate"
              component={GuarantorUpdate}
            />
            <ProtectedRoute path="/guarantorView" component={GuarantorView} />
            <ProtectedRoute
              path="/guarantorImport"
              component={GuarantorImport}
            />
            <ProtectedRoute
              path="/guarantorSearch"
              component={GuarantorSearch}
            />
            <ProtectedRoute path="/leaveInfo" component={LeaveInfo} />
            <ProtectedRoute path="/leaveInfoAdd" component={LeaveInfoAdd} />
            <ProtectedRoute path="/resignInfo" component={ResignInfo} />

            <ProtectedRoute path="/settings" component={Settings} />
          </div>
        </Switch>
        <ResponseMessage />
      </div>
    </ThemeProvider>
  );
}

export default App;
