import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import SubmenuButtons from "../../components/SubmenuButtons";
import { guarantorRoutes } from "../../constants/subMenuRoutes";
import { guarantorButtonLabel } from "../../constants/buttonLabelName";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function GuarantorImport() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={guarantorRoutes}
        buttonLabelName={guarantorButtonLabel}
      />
      <h1>Import Excel File</h1>
      <Typography paragraph></Typography>
    </main>
  );
}

export default withRouter(GuarantorImport);
