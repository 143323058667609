import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { SERVER_URL } from "../constants/constants";

const exportEmployeeViewToExcel = (apiData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const excelData = [
    ["Employee ID", apiData[0].employee_id],
    ["Employee Name", apiData[0].name],
    ["Personal file no.", apiData[0].personal_file_no],
    ["Present Designation", apiData[0].present_designation],
    ["Present Status", apiData[0].present_status],
    ["Mobile Number", apiData[0].mobile_no],
    ["Email", apiData[0].email],
    ["Present Address", apiData[0].present_address],
    ["Permanent Address", apiData[0].permanent_address],
    ["Academic Qualification", apiData[0].academic_qualification],
    ["Job Experience", apiData[0].job_experience],
    ["Father's Name", apiData[0].father_name],
    ["Mother's Name", apiData[0].mother_name],
    ["Marital Status", apiData[0].marital_status],
    ["No. of children", apiData[0].no_of_children],
    ["Home District", apiData[0].home_district],
    ["Religion", apiData[0].religion],
    ["Gender", apiData[0].gender],
    ["Nationality", apiData[0].nationality],
    ["National ID Card No.", apiData[0].nid],
    ["Personal Identification No. of Birth Certificate", apiData[0].bid],
    ["Blood Group", apiData[0].blood_group],
    ["Passport No.", apiData[0].passport_no],
    ["E-Tin", apiData[0].e_tin],
    ["Retirement Date", apiData[0].retirement_date],
    ["PRL Start Date", apiData[0].prl_start_date],
    ["Date of Birth", apiData[0].dob],
    ["Date of Joining", apiData[0].doj],
    ["Joining Designation", apiData[0].joining_designation],
    ["Present Place of Posting", apiData[0].present_place_of_posting],
    ["Joining Date at Present Place", apiData[0].joining_date_at_present_place],
    ["Last Promotion Date", apiData[0].last_promotion_date],
    ["Language(s) Known", apiData[0].language_known],
    ["Training Period", apiData[0].training_period],
    ["Letter issue date", apiData[0].letter_issue_date],
    ["Duty Time", apiData[0].duty_time],
    ["Unit Name", apiData[0].unit_name],
    ["Employee Signature", SERVER_URL + apiData[0].signature],
  ];

  // ----------------------------------------
  // one employee can have multiple guarantor
  apiData.map((item) => {
    excelData.push(["Guarantor full name", item.guarantor_full_name]);
    excelData.push(["Father name", item.guarantor_father_name]);
    excelData.push(["Present Address", item.guarantor_present_address]);
    excelData.push(["Permanent Address", item.guarantor_permanent_address]);
    excelData.push(["Profession", item.guarantor_profession]);
    excelData.push(["Institute name", item.guarantor_institute_name]);
    excelData.push([
      "Address of institute",
      item.guarantor_address_of_institute,
    ]);
    excelData.push(["Mobile number", item.guarantor_mobile_no]);
    excelData.push(["Date of Birth", item.guarantor_dob]);
    excelData.push([
      "Relation with candidate",
      item.guarantor_rel_with_candidate,
    ]);
    excelData.push(["Age", item.guarantor_age]);
    excelData.push([
      "Guarantor signature",
      SERVER_URL + item.guarantor_signature,
    ]);
  });

  // --------------------------------------------

  const ws = XLSX.utils.json_to_sheet(excelData, {
    skipHeader: true,
    origin: "A1",
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const exportGuarantorViewToExcel = (apiData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const excelData = [
    ["Guarantor full name", apiData.guarantor_full_name],
    ["Father name", apiData.guarantor_father_name],
    ["Present Address", apiData.guarantor_present_address],
    ["Permanent Address", apiData.guarantor_permanent_address],
    ["Profession", apiData.guarantor_profession],
    ["Institute name", apiData.guarantor_institute_name],
    ["Address of institute", apiData.guarantor_address_of_institute],
    ["Mobile number", apiData.guarantor_mobile_no],
    ["Date of Birth", apiData.guarantor_dob],
    ["Relation with candidate", apiData.guarantor_rel_with_candidate],
    ["Age", apiData.guarantor_age],
    ["Guarantor signature", SERVER_URL + apiData.guarantor_signature],
  ];

  const ws = XLSX.utils.json_to_sheet(excelData, {
    skipHeader: true,
    origin: "A1",
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
export { exportEmployeeViewToExcel, exportGuarantorViewToExcel };
