import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { useHistory } from "react-router-dom";
function SingleLevel({ item }) {
  let history = useHistory();
  return (
    <ListItem
      button
      onClick={() => {
        history.push(item.path);
      }}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
}

export default SingleLevel;
