import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import LoopOutlinedIcon from "@material-ui/icons/LoopOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../common/api";
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(1, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(1),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },

  paperTable: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(0),
  },
  customTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    margin: theme.spacing(0, 0, 4, 0),
  },
  customTableTdLeft: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "300px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRight: {
    border: "1px solid black",
    borderCollapse: "collapse",
    // width: "200px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  companyTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(4),
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  headerEmployee: {
    position: "relative",
    margin: theme.spacing(0, 0, 10, 0),
  },
  headerGuarantor: { position: "relative", margin: theme.spacing(0, 0, 20, 0) },
  profileImg: {
    position: "absolute",
    top: "8px",
    right: "16px",
    height: "120px",
    weight: "120px",
    border: "1px solid #0000000f",
  },
  changeStatus: {
    color: "#2a5298",
  },
  signatureImg: {
    height: "40px",
    weight: "40px",
  },
  tableTitle: {
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  searchErrorMsg: {
    color: "red",
  },
  resultTable: {
    padding: theme.spacing(0, 0, 8, 0),
  },
  dropDown: {
    height: "32px",
  },
}));

function SearchResignedEmployee() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const { control, register, handleSubmit, errors, watch } = useForm();

  const watchEmployeeSearchType = watch("employee_search_type", false);

  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "Employee ID",
      field: "employee_id",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile NO.",
      field: "mobile_no",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Unit Name",
      field: "unit_name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "NID",
      field: "nid",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Present Designation",
      field: "present_designation",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Left Job?",
      field: "employee_is_left",
      cellStyle: {
        textAlign: "left",
        fontWeight: "bold",
      },
    },
    // {
    //   title: "Email",
    //   field: "email",
    //   cellStyle: {
    //     textAlign: "left",
    //     paddingRight: "32px",
    //   },
    //   headerStyle: {
    //     paddingRight: "32px",
    //   },
    // },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchError, setSearchError] = useState("");

  const onSubmit = async (data) => {
    console.log(JSON.stringify(data));

    const test = {
      employee_id: "",
      name: "",
      mobile_no: "",
      unit_name: "",
      nid: "",
      joining_designation: "",
      present_designation: "",
    };

    for (let [key, value] of Object.entries(test)) {
      console.log(key, value);

      if (data.employee_search_type === key) {
        test[key] = data.search_input;
      }
    }

    console.log(test);

    const res = await API.post("/users/resigned/search", test);
    if (res.success === 1) {
      console.log("RES: " + JSON.stringify(res.data));
      if (res.data.length > 0) {
        // --------
        const newState = res.data.map((obj) =>
          obj.employee_is_left === 0
            ? { ...obj, employee_is_left: "NO" }
            : { ...obj, employee_is_left: "YES" }
        );

        setData(newState);
      } else {
        toast.error("No Data Found!");
      }
      setSearchError("");
    }

    // if (
    //   data.employee_id === "" &&
    //   data.name === "" &&
    //   data.mobile_no === "" &&
    //   data.nid === "" &&
    //   data.joining_designation === "" &&
    //   data.unit_name === ""
    // ) {
    //   setData([]);
    //   setSearchError(
    //     "* Employee ID or Name or Mobile NO or Unit Name or NID or Joining Designation Required!"
    //   );
    // } else {
    //   const res = await API.post("/users/search", data);
    //   if (res.success === 1) {
    //     console.log("RES: " + JSON.stringify(res.data));
    //     if (res.data.length > 0) {
    //       setData(res.data);
    //     } else {
    //       toast.error("No Data Found!");
    //     }
    //     setSearchError("");
    //   }
    // }
  };

  const [isDisableBtn, setIsDisableBtn] = useState(false);

  const [permission, setPermission] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [unitNames, setUnitNames] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const resUnitNames = await API.get(`/settings/unitName`);
      console.log("RES: " + JSON.stringify(resUnitNames));
      if (resUnitNames.data) {
        setUnitNames(resUnitNames.data);
      }

      const resDesignations = await API.get(`/settings/designation`);
      console.log("RES: " + JSON.stringify(resDesignations));
      if (resDesignations.data) {
        setDesignations(resDesignations.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    fetchData();

    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
      setIsLoading(false);
    }
  }, [permission]);

  const deleteEmployeeById = async (employee_id, name) => {
    alert(`Employee ID - ${employee_id} [${name}]\nAll data will be deleted!`);
    const res = await API.delete(`/users/${employee_id}`);
    if (res.success === 1) {
      history.go(0);
    }
    setIsDisableBtn(true);
  };

  const updateUserStatus = async (rowData) => {
    const status = rowData.employee_is_left === "YES" ? 0 : 1;
    alert("Employee Left Job Successfully");

    const data = {
      id_pk: rowData.id_pk,
      employee_is_left: status,
    };
    setIsDisableBtn(true);
    const res = await API.post("/users/update-user-status", data);

    if (res.success === 1) {
      setIsDisableBtn(false);
      history.go(0);
    } else {
      toast.error(res.message);
    }
  };

  const getActions = () => {
    const actions = [];

    // permission.includes("R")
    if (true) {
      actions.push({
        icon: () => <LoopOutlinedIcon className={classes.changeStatus} />,
        tooltip: "Change Status",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk);
          if (!isDisableBtn) {
            updateUserStatus(rowData);
          }
        },
      });
    }

    // permission.includes("R")
    if (true) {
      actions.push({
        icon: () => <InsertDriveFileOutlinedIcon />,
        tooltip: "View Documents",
        onClick: (event, rowData) => {
          history.push("/employeeViewDocument", {
            id: `${rowData.employee_id}`,
          });
        },
      });
    }
    // permission.includes("R")
    if (true) {
      actions.push({
        icon: () => <VisibilityOutlinedIcon />,
        tooltip: "View Details",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk);
          history.push("/employeeView", {
            id: `${rowData.employee_id}`,
          });
          // history.push("/employeeView", { id: 50 });
        },
      });
    }

    // permission.includes("U")
    if (true) {
      actions.push({
        icon: () => <EditOutlinedIcon />,
        tooltip: "Edit",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk);
          history.push("/employeeUpdate", {
            id: `${rowData.employee_id}`,
          });
          // history.push("/employeeView", { id: 50 });
        },
      });
    }

    // permission.includes("D")
    if (true) {
      actions.push({
        icon: () => <DeleteOutlineOutlinedIcon />,
        tooltip: "Delete User",
        onClick: (event, rowData) => {
          // console.log("ID " + rowData.id_pk); // history.push("/employeeUpdate", { id: `${rowData.employee_id}` });
          //  history.push("/employeeView", { id: 50 });
          if (!isDisableBtn) {
            deleteEmployeeById(rowData.employee_id, rowData.name);
          }
        },
      });
    }
    return actions;
  };

  const getEmployeeListPDF = async (columns, data) => {
    const doc = new jsPDF();

    const columnTitles = columns.map((columnDef) => {
      return columnDef.title;
    });

    const pdfData = data.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );

    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
    });

    doc.save(`EmployeeList.pdf`);
  };
  return (
    <>
      <Paper className={classes.paperTable}>
        <List className={classes.root}>
          {/* search input field */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container>
              <Grid item xs={12}>
                <ListItem className={classes.inputRow}>
                  <Grid container direction={matches ? "row" : "column"}>
                    <Grid item className={classes.gridPadding}>
                      {/* Search  Type */}
                      <select
                        className={classes.dropDown}
                        name="employee_search_type"
                        defaultValue=""
                        ref={register({
                          required: "This Field is Required",
                        })}
                      >
                        <option value="" disabled>
                          Select Search Type
                        </option>
                        <option value="nid">NID</option>
                        <option value="unit_name">Unit Name</option>
                        <option value="present_designation">Designation</option>
                        <option value="employee_id">Employee ID</option>
                        <option value="mobile_no">Mobile Number</option>
                        <option value="name">Employee Name</option>
                        <option value="joining_designation">
                          Designation Short Form
                        </option>
                      </select>
                      {errors.employee_search_type && (
                        <Grid item xs={12} className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.employee_search_type?.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>

                    {/* IF Select Search Type EQUAL TO unit_name OR present_designation THEN
                    DROPDOWN FIELD WILL SHOW ELSE INPUT SEARCH FIELD SHOW */}
                    {watchEmployeeSearchType === "unit_name" && (
                      <Grid item className={classes.gridPadding}>
                        {/* UNIT  Name */}
                        {isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <>
                            <select
                              className={classes.dropDown}
                              name="search_input"
                              defaultValue=""
                              ref={register({
                                required: "This Field is Required",
                              })}
                            >
                              <option value="" disabled>
                                Select Unit Name
                              </option>
                              {unitNames &&
                                unitNames.map((d, index) => {
                                  return (
                                    <option value={d.unit_name} key={index}>
                                      {d.unit_name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.search_input && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors.search_input?.message}
                                </span>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    )}
                    {watchEmployeeSearchType === "present_designation" && (
                      <Grid item className={classes.gridPadding}>
                        {/* UNIT  Name */}
                        {isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <>
                            {" "}
                            <select
                              className={classes.dropDown}
                              name="search_input"
                              defaultValue=""
                              ref={register({
                                required: "This Field is Required",
                              })}
                            >
                              <option value="" disabled>
                                Select Designation
                              </option>
                              {designations &&
                                designations.map((d, index) => {
                                  return (
                                    <option value={d.designation} key={index}>
                                      {d.designation}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.search_input && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors.search_input?.message}
                                </span>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    )}
                    {/* end here */}

                    {watchEmployeeSearchType !== "unit_name" &&
                      watchEmployeeSearchType !== "present_designation" && (
                        <>
                          {/* search input */}
                          <Grid item xs={3} className={classes.gridPadding}>
                            <TextField
                              id="search_input"
                              variant="outlined"
                              placeholder=""
                              fullWidth
                              InputProps={{
                                classes: {
                                  input: classes.textFieldInput,
                                },
                              }}
                              inputRef={register}
                              name="search_input"
                            />
                          </Grid>
                          {errors.search_input && (
                            <Grid item xs={12} className={classes.errorDiv}>
                              <span className={classes.errorMessage}>
                                * {errors.search_input?.message}
                              </span>
                            </Grid>
                          )}
                        </>
                      )}

                    {/* Employee ID */}
                    {/* <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="employee_id"
                        variant="outlined"
                        placeholder="Employee ID"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="employee_id"
                      />
                    </Grid>
                    {errors.employee_id && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.employee_id?.message}
                        </span>
                      </Grid>
                    )} */}
                    {/* Employee Name */}
                    {/* <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="name"
                        variant="outlined"
                        placeholder="Employee Name"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="name"
                      />
                    </Grid>
                    {errors.name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.name?.message}
                        </span>
                      </Grid>
                    )} */}
                    {/* Mobile Number */}
                    {/* <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="mobile_no"
                        variant="outlined"
                        placeholder="Mobile Number"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="mobile_no"
                      />
                    </Grid>
                    {errors.mobile_no && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.mobile_no?.message}
                        </span>
                      </Grid>
                    )} */}

                    {/* Unit Name */}
                    {/* <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="unit_name"
                        variant="outlined"
                        placeholder="Unit Name"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="unit_name"
                      />
                    </Grid>
                    {errors.unit_name && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.unit_name?.message}
                        </span>
                      </Grid>
                    )} */}

                    {/*nid */}
                    {/* <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="nid"
                        variant="outlined"
                        placeholder="NID"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="nid"
                      />
                    </Grid>
                    {errors.nid && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.nid?.message}
                        </span>
                      </Grid>
                    )} */}

                    {/*joining designation */}
                    {/* <Grid item xs={3} className={classes.gridPadding}>
                      <TextField
                        id="joining_designation"
                        variant="outlined"
                        placeholder="Joining Designation"
                        fullWidth
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register}
                        name="joining_designation"
                      />
                    </Grid>
                    {errors.joining_designation && (
                      <Grid item xs={12} className={classes.errorDiv}>
                        <span className={classes.errorMessage}>
                          * {errors.joining_designation?.message}
                        </span>
                      </Grid>
                    )} */}
                    {/* Search Button */}
                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SearchOutlinedIcon />}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item xs={12}>
                <ListItem className={classes.inputRow}>
                  <Grid container alignItems="center">
                    <span className={classes.searchErrorMsg}>
                      {searchError}
                    </span>
                  </Grid>
                </ListItem>
              </Grid>
            </Grid>
          </form>
        </List>
      </Paper>

      {data.length > 0 ? (
        <>
          <h1>{data.length} Records Found</h1>
          <Paper className={classes.resultTable}>
            <MaterialTable
              title=""
              columns={columns}
              data={data}
              onRowClick={(evt, selectedRow) =>
                setSelectedRow(selectedRow.tableData.id)
              }
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => {
                  getEmployeeListPDF(columns, data);
                },
                search: false,
                paging: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  textAlign: "left",
                  paddingRight: "32px",
                },

                actionsCellStyle: {
                  paddingRight: "32px",
                  justifyContent: "center",
                },
              }}
              //   actions={!isLoading && getActions()}
            />
          </Paper>
        </>
      ) : null}
    </>
  );
}

export default withRouter(SearchResignedEmployee);
