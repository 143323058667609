import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import API from "../../common/api";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import SubmenuButtons from "../../components/SubmenuButtons";
import { useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { guarantorRoutes } from "../../constants/subMenuRoutes";
import { guarantorButtonLabel } from "../../constants/buttonLabelName";
import { withRouter } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Guarantor() {
  const classes = useStyles();
  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "Employee ID",
      field: "guarantor_employee_id",

      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Guarantor Full Name",
      field: "guarantor_full_name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile Number",
      field: "guarantor_mobile_no",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Profession",
      field: "guarantor_profession",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Present Address",
      field: "guarantor_present_address",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await API.get("/guarantors");
      setData(res.data);
    }
    fetchData();
  }, []);

  const getEmployeeListPDF = async (columns, data) => {
    const doc = new jsPDF();

    const columnTitles = columns.map((columnDef) => {
      return columnDef.title;
    });

    const pdfData = data.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );

    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
    });

    doc.save(`GuarantorList.pdf`);
  };

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={guarantorRoutes}
        buttonLabelName={guarantorButtonLabel}
      />
      <h1>Guarantor List</h1>
      <Typography paragraph></Typography>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          exportButton: true,
          exportPdf: (columns, data) => {
            getEmployeeListPDF(columns, data);
          },
          search: false,
          pageSize: 20,
          pageSizeOptions: [50, 100, 200],
          actionsColumnIndex: -1,
          headerStyle: {
            textAlign: "left",
            paddingRight: "32px",
          },

          actionsCellStyle: {
            paddingRight: "32px",
            justifyContent: "center",
          },
        }}
        actions={[
          {
            icon: () => <VisibilityOutlinedIcon />,
            tooltip: "View Details",
            onClick: (event, rowData) => {
              // console.log("ID " + rowData.id_pk);
              history.push("/guarantorView", {
                id: `${rowData.guarantor_id_pk}`,
              });
              // history.push("/guarantorView", { id: 50 });
            },
          },
          {
            icon: () => <EditOutlinedIcon />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              // console.log("ID " + rowData.id_pk);
              history.push("/guarantoUpdate", {
                id: `${rowData.guarantor_id_pk}`,
              });
              // history.push("/employeeView", { id: 50 });
            },
          },
          // (rowData) => ({
          //   icon: "delete",
          //   tooltip: "Delete User",
          //   onClick: (event, rowData) => {},
          //   disabled: rowData.birthYear < 2000,
          // }),
        ]}
      />
    </main>
  );
}

export default withRouter(Guarantor);
