import AppBar from "@material-ui/core/AppBar";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import StarBorder from "@material-ui/icons/StarBorder";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { menu } from "./menu/menu";
import MenuItem from "./menu/MenuItem";
import API from "../common/api";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "white",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    background:
      "linear-gradient(to left, #1e3c72 0%, #1e3c72 1%, #2a5298 100%)",
    color: "white",
  },
  logoTitle: {
    color: "white",
    flexGrow: 1,
  },
  closeButton: {
    color: "white",
  },
  employeeIcon: {
    color: "white",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  helpMenu: {
    color: "white",
    textDecoration: "none",
    paddingRight: theme.spacing(5),
  },
}));

export default function Sidebar() {
  let history = useHistory();
  const isAuthenticated = localStorage.getItem("accessToken");

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [openMenu, setOpenMenu] = React.useState(true);

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    history.push("/");
    // const res = await API.post("/logout");
    // if (res.success === 1) {
    //   history.push("/employee");
    // }
  };
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={clsx(classes.logoTitle)}>
            Express One Limited
          </Typography>
          <a
            className={clsx(classes.helpMenu)}
            href="https://drive.google.com/file/d/11n4wgHSBE_jOlfwss1MOQNYoU3sqPgjZ/view?usp=sharing"
            target="_blank"
          >
            Download App
          </a>
          {isAuthenticated ? (
            <Button color="inherit" onClick={logout}>
              LogOut
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={handleDrawerClose}
            className={clsx(classes.closeButton)}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
          {/* {sidebarRoutes.map((data, index) => {
            if (data.path === "/employee") {
              return (
                <div key={index}>
                  <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inbox" />
                    {openMenu ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Starred" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <Divider />
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(data.path);
                    }}
                  >
                    <ListItemIcon>
                      <PeopleAltOutlinedIcon
                        className={clsx(classes.employeeIcon)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={data.name} />
                  </ListItem>
                  <Divider />
                </div>
              );
            }
          })} */}
        </List>
      </Drawer>
    </>
  );
}
